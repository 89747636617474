import styled, { css } from 'styled-components';
import { TableRow } from 'components/Table';

interface TableRowStyledProps {
  $isInvalidStatus: boolean;
}

export const TableRowStyled = styled(TableRow)<TableRowStyledProps>`
  ${({ $isInvalidStatus, theme }) =>
    $isInvalidStatus &&
    css`
      td,
      span {
        color: ${theme.palette.zml.grey100};
      }
    `}
`;
