import { Box } from '@mui/system';
import { useEffect } from 'react';
import { addDays, isLeapYear, isValid } from 'date-fns';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'hooks/translation';
import { Button } from 'components/Button';
import { DatePicker, DatePickerProps } from 'components/Forms/DatePicker';
import { Dropdown } from 'components/Forms/Dropdown';
import { useForm } from 'hooks/form';
import { apiErrorHandler, getI18nNamespace } from 'utils/helpers';
import { useBenefitBook } from 'components/BenefitBookDialog/useBenefitBook';
import { BenefitType } from 'components/BenefitBookDialog';
import { Dispatch } from 'store';
import { yup } from 'services/yup';

import { DateSchema } from 'yup';
import { config } from 'services/config';
import { DialogContent, Footer } from '../../BenefitBookDialog.styled';

const companyTranslator = getI18nNamespace('company');

const intervalOptions: DropdownOption<AutomatedBenefitInterval>[] = [
  {
    label: companyTranslator(
      'benefits.benefitBookDialog.automatedBenefit.interval.monthly',
    ),
    value: 'monthly',
  },
  {
    label: companyTranslator(
      'benefits.benefitBookDialog.automatedBenefit.interval.quarterly',
    ),
    value: 'quarterly',
  },
  {
    label: companyTranslator(
      'benefits.benefitBookDialog.automatedBenefit.interval.annually',
    ),
    value: 'annually',
  },
  {
    label: companyTranslator(
      'benefits.benefitBookDialog.automatedBenefit.interval.once',
    ),
    value: 'once',
  },
];

const today = new Date();
const quarterlyMonths = [0, 3, 6, 9];

interface AutomatedBenefitForm {
  interval: AutomatedBenefitInterval | '';
  startsAt: Date | null;
  expiresAt: Date | null;
}

interface AutomatedBenefitFormData {
  interval: AutomatedBenefitInterval;
  startsAt: Date;
  expiresAt: Date | null;
}

const initialValues: AutomatedBenefitForm = {
  interval: '',
  startsAt: null,
  expiresAt: null,
};

const validationSchema = yup.object().shape({
  interval: yup
    .string()
    .required()
    .label(
      companyTranslator(
        'benefits.benefitBookDialog.automatedBenefit.intervalLabel',
      ),
    ),
  startsAt: yup
    .date()
    .min(today)
    .nullable()
    .required()
    .when('interval', {
      is: 'once',
      then: schema =>
        schema.label(
          companyTranslator(
            'benefits.benefitBookDialog.automatedBenefit.startsAtOnceLabel',
          ),
        ),
      otherwise: schema =>
        schema.when('interval', {
          is: 'monthly',
          then: () =>
            schema
              .label(
                companyTranslator(
                  'benefits.benefitBookDialog.automatedBenefit.startsAtMonthlyLabel',
                ),
              )
              .min(today),
          otherwise: () =>
            schema.when('interval', {
              is: 'quarterly',
              then: () =>
                schema
                  .label(
                    companyTranslator(
                      'benefits.benefitBookDialog.automatedBenefit.startsAtQuarterlyLabel',
                    ),
                  )
                  .test(
                    'is-valid-month',
                    companyTranslator(
                      'benefits.benefitBookDialog.automatedBenefit.invalidQuarterlyDateMessage',
                    ),
                    (value = null) =>
                      value !== null &&
                      quarterlyMonths.includes(value.getMonth()),
                  ),
              otherwise: () =>
                schema.label(
                  companyTranslator(
                    'benefits.benefitBookDialog.automatedBenefit.startsAtAnnuallyLabel',
                  ),
                ),
            }),
        }),
    }),
  expiresAt: yup
    .date()
    .nullable()
    .when('interval', {
      is: 'monthly',
      then: schema =>
        schema
          .label(
            companyTranslator(
              'benefits.benefitBookDialog.automatedBenefit.expiresAtMonthlyLabel',
            ),
          )
          .when(
            'startsAt',
            (
              startsAt: AutomatedBenefitForm['startsAt'],
              expiresAtSchema: DateSchema<Date | null | undefined>,
            ) => expiresAtSchema.min(addDays(startsAt || today, 1)),
          ),
      otherwise: schema =>
        schema.when('interval', {
          is: 'quarterly',
          then: () =>
            schema
              .label(
                companyTranslator(
                  'benefits.benefitBookDialog.automatedBenefit.startsAtQuarterlyLabel',
                ),
              )
              .test(
                'is-valid-month',
                companyTranslator(
                  'benefits.benefitBookDialog.automatedBenefit.invalidQuarterlyDateMessage',
                ),
                (value = null) =>
                  value !== null && quarterlyMonths.includes(value.getMonth()),
              ),
          otherwise: () =>
            schema
              .label(
                companyTranslator(
                  'benefits.benefitBookDialog.automatedBenefit.expiresAtAnnuallyLabel',
                ),
              )
              .when(
                'startsAt',
                (
                  startsAt: AutomatedBenefitForm['startsAt'],
                  toDateSchema: DateSchema<Date | null | undefined>,
                ) => toDateSchema.min(addDays(startsAt || today, 1)),
              ),
        }),
    }),
});

export const AutomatedBenefit = () => {
  const { benefit, changeAutomatedBenefitData, goNext } = useBenefitBook();

  const { t } = useTranslation('company');
  const dispatch = useDispatch<Dispatch>();

  const getDropdownOptions = () => {
    const praesentFilterIntervals: AutomatedBenefitInterval[] = [
      'monthly',
      'quarterly',
    ];

    return intervalOptions.filter(option => {
      const { value } = option;

      if (
        benefit?.benefitTypeId === BenefitType.DE_PRAESENT &&
        praesentFilterIntervals.includes(value)
      ) {
        return false;
      }

      if (config.benefits.disableFrequencyOnce && value === 'once') {
        return false;
      }

      return true;
    });
  };

  const intervalDropdownOptions = getDropdownOptions();

  const hasOnlyOneIntervalOption = intervalDropdownOptions.length === 1;

  const onSubmit = (values: AutomatedBenefitFormData) => {
    try {
      const { interval, startsAt, expiresAt } = values;

      const _expiresAt = interval === 'once' ? startsAt : (expiresAt as Date);

      changeAutomatedBenefitData({
        interval,
        startsAt,
        expiresAt: _expiresAt,
      });

      goNext();
    } catch (error) {
      apiErrorHandler(error, dispatch);
    }
  };

  const {
    getFieldProps,
    handleSubmit,
    values,
    setValue,
    isSubmitting,
    setErrors,
  } = useForm<AutomatedBenefitForm>({
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (!hasOnlyOneIntervalOption) return;

    const [onlyIntervalOption] = intervalDropdownOptions;

    setValue('interval', onlyIntervalOption.value);
  }, [hasOnlyOneIntervalOption]);

  useEffect(() => {
    setValue('expiresAt', null);
    setErrors({});
  }, [values.interval]);

  const isEmpty = !values.interval;
  const isOnce = values.interval === 'once';
  const isMonthly = values.interval === 'monthly';
  const isQuarterly = values.interval === 'quarterly';
  const isAnnually = values.interval === 'annually';

  const isExpiresAtFieldVisible = !isEmpty && !isOnce;
  const isStartsAtFieldVisible = !isEmpty;
  const isExplanationMessageVisible = !isOnce && !isEmpty;

  const isAllowedQuarterlyMonth = (date: Date) => {
    return quarterlyMonths.includes(date.getMonth());
  };

  const onChangeDatePickerValue =
    (field: keyof Pick<AutomatedBenefitForm, 'startsAt' | 'expiresAt'>) =>
    (value: AutomatedBenefitForm[typeof field]) => {
      setValue(field, value);
    };

  const getDatePickerProps = (
    field: keyof Pick<AutomatedBenefitForm, 'startsAt' | 'expiresAt'>,
  ): DatePickerProps => {
    const minDateStartsAt = addDays(today, 1);

    const resolveMinQuarterlyDate = () => {
      const todayMonth = today.getMonth();

      const closestAllowedMonthIndex = quarterlyMonths.reduce(
        (acc, curr, index) => {
          if (Math.abs(curr - todayMonth) < Math.abs(acc - todayMonth)) {
            return index;
          }

          return acc;
        },
        0,
      );

      const isCurrentMonthQuarterly = isAllowedQuarterlyMonth(today);

      if (isCurrentMonthQuarterly) return today;

      const closestAllowedMonth = new Date().setMonth(
        quarterlyMonths[closestAllowedMonthIndex],
      );

      const firstDayOfClosestAllowedMonth = new Date(
        closestAllowedMonth,
      ).setDate(1);

      return new Date(firstDayOfClosestAllowedMonth);
    };

    if (field === 'startsAt') {
      if (isOnce) {
        return {
          minDate: minDateStartsAt,
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.startsAtOnceLabel',
          ),
        };
      }

      if (isMonthly) {
        return {
          minDate: minDateStartsAt,
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.startsAtMonthlyLabel',
          ),
        };
      }

      if (isQuarterly) {
        return {
          minDate: resolveMinQuarterlyDate(),
          shouldDisableDate: date => !isAllowedQuarterlyMonth(date),
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.startsAtQuarterlyLabel',
          ),
        };
      }

      if (isAnnually) {
        return {
          minDate: minDateStartsAt,
          shouldDisableDate: date => {
            if (!isLeapYear(date)) return false;

            const isFebruary29 = date.getDate() === 29 && date.getMonth() === 1;

            return isFebruary29;
          },
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.startsAtAnnuallyLabel',
          ),
        };
      }
    }

    if (field === 'expiresAt') {
      const minDateExpiresAt =
        values.startsAt && isValid(values.startsAt)
          ? addDays(values.startsAt, 1)
          : addDays(minDateStartsAt, 1);

      if (isMonthly) {
        return {
          minDate: minDateExpiresAt,
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.expiresAtMonthlyLabel',
          ),
        };
      }

      if (isQuarterly) {
        return {
          minDate: minDateExpiresAt,
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.expiresAtQuarterlyLabel',
          ),
        };
      }

      if (isAnnually) {
        return {
          label: t(
            'benefits.benefitBookDialog.automatedBenefit.expiresAtAnnuallyLabel',
          ),
          minDate: minDateExpiresAt,

          placeholder: t(
            'benefits.benefitBookDialog.automatedBenefit.expiresAtAnnuallyPlaceholder',
            { disableParentElement: true },
          ),
        };
      }
    }

    return {};
  };

  const getExplanationMessage = () => {
    if (isMonthly) {
      return t(
        'benefits.benefitBookDialog.automatedBenefit.explanationMessageMonthly',
      );
    }

    if (isQuarterly) {
      return t(
        'benefits.benefitBookDialog.automatedBenefit.explanationMessageQuarterly',
      );
    }

    return t(
      'benefits.benefitBookDialog.automatedBenefit.explanationMessageAnnually',
    );
  };

  return (
    <DialogContent
      $minHeight="23.5rem"
      data-testid="benefit-book-digital-benefit"
    >
      <form onSubmit={handleSubmit} noValidate>
        <Dropdown
          {...getFieldProps('interval')}
          disabled={hasOnlyOneIntervalOption}
          options={intervalDropdownOptions}
          label={t(
            'benefits.benefitBookDialog.automatedBenefit.intervalLabel',
            { disableParentElement: true },
          )}
          inputProps={{
            'data-testid': 'interval-dropdown',
          }}
        />

        {isStartsAtFieldVisible && (
          <DatePicker
            {...getDatePickerProps('startsAt')}
            {...getFieldProps('startsAt')}
            disablePast
            onChange={onChangeDatePickerValue('startsAt')}
            required
            inputTestId="starts-at-picker"
          />
        )}

        {isExpiresAtFieldVisible && (
          <DatePicker
            minDate={today}
            {...getDatePickerProps('expiresAt')}
            {...getFieldProps('expiresAt')}
            disablePast
            onChange={onChangeDatePickerValue('expiresAt')}
            inputTestId="expires-at-picker"
          />
        )}

        {isExplanationMessageVisible && (
          <Box component="p" height="2.5rem" fontSize="0.875rem" my="1rem">
            {getExplanationMessage()}
          </Box>
        )}

        <Footer>
          <Button
            type="submit"
            fullWidth
            isLoading={isSubmitting}
            data-testid="automated-benefit-submit-button"
          >
            {t('benefits.benefitBookDialog.nextButton')}
          </Button>
        </Footer>
      </form>
    </DialogContent>
  );
};
