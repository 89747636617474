/* eslint-disable import/no-duplicates */
import { ReactNode, useEffect, useRef, useState } from 'react';
import { isValid } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker as MaterialDatePicker,
  LocalizationProvider,
  DatePickerProps as MaterialDatePickerProps,
  PickerChangeHandlerContext,
  DateValidationError,
} from '@mui/x-date-pickers';

import { useTranslation } from 'hooks/translation';
import { formatDateToAppWithoutTz } from 'utils/helpers';
import { useDisclosure } from 'hooks/disclosure';
import { useResponsiveness } from 'hooks/responsiveness';
import { DateInput } from './components/DateInput';
import { DateInputProps } from './components/DateInput/DateInput';

interface Props extends Partial<MaterialDatePickerProps<Date, boolean>> {
  errortext?: string;
  label?: ReactNode;
  onChange?(date: Date | null): void;
  value?: Date | null;
  initialDate?: Date;
  disableFuture?: boolean;
  inputTestId?: string;
  placeholder?: string;
  required?: boolean;
  openOnFocus?: boolean;
}

export type DatePickerProps = Props;

export const DatePicker = (props: Props) => {
  const {
    label,
    onChange,
    value = null,
    initialDate,
    disableFuture,
    inputTestId,
    errortext,
    placeholder,
    required,
    openOnFocus,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState<string | Date>('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t, i18n } = useTranslation(['components', 'form']);
  const isLanguageDE = i18n.language.toLowerCase().includes('de');
  const locale = isLanguageDE ? de : enUS;

  const inputRef = useRef<HTMLInputElement>(null);

  const { isMobileOrTablet } = useResponsiveness();

  const isInputFocused = document.activeElement === inputRef.current;

  useEffect(() => {
    if (isInputFocused) return;

    setInputValue(formatDateToAppWithoutTz(value));
  }, [value]);

  useEffect(() => {
    if (isValid(value)) {
      setInputValue(formatDateToAppWithoutTz(value));
    }
  }, []);

  const onChangeDate = (
    date: Date | null,
    _: PickerChangeHandlerContext<DateValidationError>,
    _inputValue = '',
  ) => {
    if (date && !_inputValue) {
      setInputValue(formatDateToAppWithoutTz(date));
      onChange?.(date);
      return;
    }

    setInputValue(_inputValue);
    onChange?.(date);
  };

  const isFilled = Boolean(value) || Boolean(inputValue);

  const clearValue = () => {
    onChange?.(null);
    setInputValue('');
  };

  const onPickerOpen = () => {
    if (process.env.NODE_ENV === 'test' || isOpen) return;

    onOpen();

    if (openOnFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
    }
  };

  const onPickerClose = () => {
    onClose();

    if (openOnFocus) {
      setTimeout(() => {
        inputRef.current?.blur();
      }, 10);
    }
  };

  const onInputClick = () => {
    if (!openOnFocus && !isMobileOrTablet) return;

    if (isOpen) {
      onPickerClose();

      return;
    }

    onPickerOpen();
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={{
        cancelButtonLabel: t('datePicker.cancelText', {
          disableParentElement: true,
        }),
        okButtonLabel: t('datePicker.okText', { disableParentElement: true }),
      }}
    >
      <MaterialDatePicker
        open={isOpen}
        onOpen={onPickerOpen}
        onClose={onPickerClose}
        label={label}
        disableFuture={disableFuture}
        defaultValue={initialDate}
        onChange={onChangeDate}
        closeOnSelect
        value={value}
        format={t('dateFormat', { disableParentElement: true })}
        autoFocus={false}
        inputRef={inputRef}
        slots={{ field: DateInput }}
        slotProps={{
          popper: { onFocus: onPickerOpen },
          // @ts-expect-error: @mui/x-date-pickers expect a field that receives a date object as value
          field: {
            errortext,
            inputTestid: inputTestId,
            placeholder,
            required,
            onClick: onInputClick,
            onChange: onChangeDate,
            value: inputValue,
            clearValue,
            isFilled,
          } as unknown as any as DateInputProps,
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};
