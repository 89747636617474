import { List } from '@mui/material';
import { IoPeopleOutline } from 'react-icons/io5';
import {
  MdAccountBalance,
  MdOutlineCode,
  MdOutlineStorefront,
} from 'react-icons/md';
import { FaCogs } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { BsLink45Deg } from 'react-icons/bs';
import { HiOutlineCode } from 'react-icons/hi';
import { useMemo } from 'react';
import { ReactComponent as BillingIcon } from 'assets/icon/billing_details.svg';
import paths, {
  getPathname,
  isCompany,
  isRetailer,
  isNetwork,
} from 'utils/paths';
import { getI18nNamespace } from 'utils/helpers';
import { MenuItem } from './MenuItem';
import { SidebarMenuItem } from './MainMenu';

const retailerTranslator = getI18nNamespace('retailer');

export const retailerSettingsMenuItems: SidebarMenuItem[] = [
  {
    description: retailerTranslator('retailers.sidebar.locations'),
    icon: MdOutlineStorefront,
    path: paths.STORES,
  },
  {
    description: retailerTranslator('retailers.sidebar.team'),
    icon: IoPeopleOutline,
    path: paths.USERS,
  },
  {
    description: retailerTranslator('retailers.sidebar.billing'),
    icon: MdAccountBalance,
    path: paths.BILLING,
  },
  {
    description: retailerTranslator('retailers.sidebar.settings'),
    icon: FaCogs,
    path: paths.SETTINGS,
  },
  {
    description: retailerTranslator('retailers.sidebar.developer'),
    icon: MdOutlineCode,
    path: paths.DEVELOPERS,
  },
];

const companyTranslator = getI18nNamespace('company');

export const companySettingsMenuItems: SidebarMenuItem[] = [
  {
    description: companyTranslator('benefits.sidebar.billing'),
    icon: MdAccountBalance,
    path: paths.BILLING,
  },
  {
    description: companyTranslator('benefits.sidebar.team'),
    icon: IoPeopleOutline,
    path: paths.TEAM,
    subPages: [paths.TEAM_MEMBER],
  },
  {
    description: companyTranslator('benefits.sidebar.settings'),
    icon: FaCogs,
    path: paths.SETTINGS,
  },
];

const networkTranslator = getI18nNamespace('network');

export const networkSettingsMenuItems: SidebarMenuItem[] = [
  {
    description: networkTranslator('networks.sidebar.billingDetails'),
    icon: BillingIcon,
    path: paths.BILLING_DETAILS,
  },
  {
    description: networkTranslator('networks.sidebar.team'),
    icon: IoPeopleOutline,
    path: paths.TEAM,
    subPages: [paths.TEAM_MEMBER],
  },
  {
    description: networkTranslator('networks.sidebar.links'),
    icon: BsLink45Deg,
    path: paths.LINKS,
  },
  {
    description: networkTranslator('networks.sidebar.api'),
    icon: HiOutlineCode,
    path: paths.API,
  },
];

export const getSettingsMenuItems = (currentPath?: string) => {
  const pathname = getPathname();

  const path = currentPath || pathname;

  if (isCompany(path)) {
    return companySettingsMenuItems;
  }

  if (isRetailer(path)) {
    return retailerSettingsMenuItems;
  }

  if (isNetwork(path)) {
    return networkSettingsMenuItems;
  }

  return [];
};

interface Props {
  toggleDrawer?(): void;
  authRole: AuthRole;
}

export const SettingsMenu = (props: Props) => {
  const { toggleDrawer, authRole } = props;

  const { pathname } = useLocation();

  const settingsMenuItems = useMemo(() => {
    if (authRole === 'USER') return [];

    const menuItems = getSettingsMenuItems(pathname);

    if (authRole === 'ACCOUNTANT') {
      return menuItems.filter(menu => menu.path === paths.BILLING);
    }

    return menuItems;
  }, [authRole, pathname]);

  return (
    <List>
      {settingsMenuItems?.map(menu => (
        <MenuItem
          key={menu.description}
          description={menu.description}
          path={menu.path}
          icon={menu.icon}
          onClick={toggleDrawer}
        />
      ))}
    </List>
  );
};
