import styled from 'styled-components';
import { FaLongArrowAltUp } from 'react-icons/fa';
import { WizardDialog } from 'components/WizardDialog';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const WizardDialogStyled = styled(WizardDialog)`
  div.MuiDialog-paper {
    width: 28.188rem;
    min-height: 20.875rem;
  }
`;

interface DialogContentProps {
  $minHeight?: `${number}rem`;
}

export const DialogContent = styled.div<DialogContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: ${({ $minHeight }) => $minHeight || '16.5rem'};
  height: 100%;
  width: 100%;

  padding: 1rem;
  padding-top: 0;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  ${MOBILE_BREAKPOINT} {
    height: 80vh;

    > form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`;

export const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 300;

  p {
    text-align: center;
    font-size: 0.875rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  margin-bottom: 0;
  padding-top: 1.25rem;
  width: 15.125rem;

  ${MOBILE_BREAKPOINT} {
    margin-top: auto;
  }
`;

export const FaLongArrowAltUpStyled = styled(FaLongArrowAltUp)`
  transform: rotate(45deg);
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.625rem;
  width: 100%;

  strong {
    font-size: 1.063rem;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-size: 0.875rem;
    text-align: left;
    margin: 1rem 0 0 0;
  }
`;

export const OptionButtonStyled = styled.button`
  display: flex;
  min-height: 6.25rem;
  width: 100%;

  border: none;
  border-radius: 0.25rem;

  padding: 1.188rem 0.5rem 1.188rem 1.12rem;
  margin-top: 1rem;

  background-color: ${({ theme }) => theme.palette.zml.grey50};
  color: ${({ theme }) => theme.palette.zml.blue800};
  box-shadow: 0 0.188rem 0.375rem
    ${({ theme }) => theme.palette.zml.black14Opacity};

  & + & {
    margin-top: 2rem;
  }

  :hover {
    box-shadow: 0 0.188rem 0.375rem ${({ theme }) => theme.palette.primary.main};
  }

  :disabled {
    background-color: ${({ theme }) => theme.palette.zml.grey50};
    color: ${({ theme }) => theme.palette.zml.grey400};

    > span {
      color: ${({ theme }) => theme.palette.zml.grey400};
    }

    svg {
      color: ${({ theme }) => theme.palette.zml.grey400};
    }

    :hover {
      box-shadow: 0 0.188rem 0.375rem
        ${({ theme }) => theme.palette.zml.black14Opacity};
      cursor: not-allowed;
    }
  }
`;
