import { matchPath } from 'react-router-dom';
import { config } from 'services/config';
import { homepage } from '../../package.json';

export const ROOT_HOSTNAME = config.rootHostname;
export const ROOT_ROUTE = homepage;
export const ROOT_RETAILER = '/retailer';
export const ROOT_COMPANY = '/company';
export const ROOT_NETWORK = '/network';

export enum ZmyleLinks {
  ZMYLE = 'https://www.zmyle.de/',
  LEGAL = 'https://legal.zmyle.de/sonstiges/imprint',
  PRIVACY_POLICY = 'https://legal.zmyle.de/privacy-policy/datenschutzerklaerung',
  GIFTCARD_TERMS = 'https://legal.zmyle.de/zmyle-stores/gutscheinregeln-fuer-verbraucher',
  BENEFITS_TERMS = 'https://legal.zmyle.de/zmyle-benefits-agb/agb-fuer-arbeitgeber',
  BENEFITS_AGREEMENTS = 'https://legal.zmyle.de/zmyle-benefits/rahmenvertrag',
  SUPPORT = 'https://support.zmyle.de/',
  BENEFIT_SUPPORT = 'https://support.zmyle.de/benefits-fuer-arbeitgeber',
  ACCOUNT_DASHBOARD_SUPPORT = 'https://support.zmyle.de/profil-and-accounts',
  STORE_SUPPORT = 'https://support.zmyle.de/stores-fuer-akzeptanzstellen',
  NETWORK_SUPPORT = 'https://support.networks.zmyle.de/',
  PROFILE_SUPPORT = 'https://support.zmyle.de/profil-and-accounts/user-account',
  COMMUNITY_TERMS = 'community/retailer-terms',
  RETAILER_TERMS = 'st/retailer-terms',
  ORDER_CARD_MANUAL = 'https://support.zmyle.de/benefits-fuer-arbeitgeber/branding/plastikkarten',
  ORDER_CARD_FORM = 'https://form.jotform.com/220903126678356',
  STANDARD_API_KEY_INFO = 'https://support.zmyle.de/stores-fuer-akzeptanzstellen/verwaltung/entwickler/standard-schlussel',
  PARTNERS_API_KEY_INFO = 'https://support.zmyle.de/stores-fuer-akzeptanzstellen/verwaltung/entwickler/partner-schlussel',
}

const paths = {
  HOME: '/',
  PROFILE: '/profile',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgotPassword',
  SIGNUP: '/signup',
  SIGNUPSTORE: '/signupStore',
  SIGNUP_COMPANY: '/signupCompany',
  RETAILER: `${ROOT_RETAILER}/:retailerId`,
  COMPANY: `${ROOT_COMPANY}/:companyId`,
  NETWORK: `${ROOT_NETWORK}/:networkId`,
  MAGIC_URL: '/magicurl',
  PROFILE_PERMISSION: '/profilepermission/s/:storeId/n/:networkId',
  RESET_PASSWORD: '/users/resetpassword',

  DASHBOARD: '/dashboard',
  TRANSACTIONS: '/transactions',
  BILLING: '/billing',
  INVOICES: '/invoices',
  REPORTS: '/reports',
  SYSTEM: '/system',
  GIFTCARDS_DETAILS: '/giftcards/:giftcardId',
  LOCATIONS: '/locations',
  MARKETING: '/marketing',
  EMPLOYEES: '/employees',
  IMPORT: '/import',
  EMPLOYEE: '/employee/:employeeId',
  AUTOMATED_BENEFIT_DETAILS: '/automatedBenefit/:automatedBenefitId',
  TRANSACTION_DETAILS: '/transactions/:transactionId',
  BUSINESS_ACCOUNT: '/',
  SERVICES: '/services',
  GIFTCARD_DETAILS: '/giftcard/:giftcardId',
  STORE_DETAILS: '/stores/:storeId',
  STORES: '/stores',
  BENEFITS: '/benefits_accounts',
  BENEFIT_DETAILS: '/benefits/:benefitsId',
  BILLING_DETAILS: '/billing',
  FEES: '/fees',
  STATEMENTS: '/statements',
  TEAM: '/team',
  TEAM_MEMBER: '/teamMember/:teamMemberId',
  USERS: '/users',
  USER_DETAILS: '/users/:userId',
  LINKS: '/links',
  API: '/api',
  SALES_OVERVIEW: '/sales_overview',
  EMPLOYER: '/employer',
  SETTINGS: '/settings',
  DEVELOPERS: '/developers',
  API_KEYS_DETAILS: '/apikeys/:apiTenantId',
  PARTNER_API_KEYS_DETAILS: '/partnerkeys/:partnerKeyId',
  ANALYTICS: '/statistics/interval',
} as const;

export default paths;

export const isRetailer = (path?: string) => {
  if (!path) return false;

  const pathMatches = [
    ROOT_RETAILER,
    ROOT_ROUTE + ROOT_RETAILER,
    paths.RETAILER,
    ROOT_ROUTE + paths.RETAILER,
  ];

  return pathMatches.some(pathMatch => matchPath(path, { path: pathMatch }));
};

export const isAccountsDashboard = (path?: string) => {
  if (!path) return false;

  const pathMatches = [paths.DASHBOARD, ROOT_ROUTE + paths.DASHBOARD];

  return pathMatches.some(pathMatch => matchPath(path, { path: pathMatch }));
};

export const isCompany = (path?: string) => {
  if (!path) return false;

  const pathMatches = [
    ROOT_COMPANY,
    ROOT_ROUTE + ROOT_COMPANY,
    paths.COMPANY,
    ROOT_ROUTE + paths.COMPANY,
  ];

  return pathMatches.some(pathMatch => matchPath(path, { path: pathMatch }));
};

export const isUserProfile = (path?: string) => {
  if (!path) return false;

  const pathMatches = [paths.PROFILE, ROOT_ROUTE + paths.PROFILE];

  return pathMatches.some(pathMatch => matchPath(path, { path: pathMatch }));
};

export const isNetwork = (path?: string) => {
  if (!path) return false;

  const pathMatches = [
    ROOT_NETWORK,
    ROOT_ROUTE + ROOT_NETWORK,
    paths.NETWORK,
    ROOT_ROUTE + paths.NETWORK,
  ];

  return pathMatches.some(pathMatch => matchPath(path, { path: pathMatch }));
};

interface RouteIdParam {
  companyId?: string;
  retailerId?: string;
  networkId?: string;
}

export const getRouteId = (path: string) => {
  const match = matchPath<RouteIdParam>(path, {
    path: [paths.COMPANY, paths.RETAILER, paths.NETWORK],
  });

  if (!match) return '';

  const { companyId, networkId, retailerId } = match.params;

  return companyId || networkId || retailerId;
};

export const getPathname = () => {
  const { pathname } = window.location;

  return pathname.replace(ROOT_ROUTE, '');
};
