import {
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styled, { css } from 'styled-components';

interface DialogStyledProps extends DialogProps {
  $size?: 'md' | 'xl' | null;
}

const getDialogWidth = (size: DialogStyledProps['$size']) => {
  if (!size) return '18.75rem';

  const width: Record<typeof size, string> = {
    md: '28.188rem',
    xl: '40rem',
  };

  return width[size];
};

export const DialogStyled = styled(Dialog)<DialogStyledProps>`
  div.MuiDialog-paper {
    color: inherit;

    ${({ fullScreen, $size }) =>
      !fullScreen &&
      css`
        width: ${getDialogWidth($size)};
      `}

    padding: 0.5rem 1rem;
  }

  .MuiDialogContent-root {
    padding: 0;
  }
`;

export const Title = styled.strong`
  font-size: 1.375rem;
  font-weight: 300;
  transform: translateY(0.6rem);
`;

export const DialogTitleStyled = styled(DialogTitle)`
  padding: 0 0 1rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: center;
  font-size: 1.5rem;

  button,
  span {
    width: 2rem;
    height: 2rem;
  }
`;

export const DialogActionsStyled = styled(DialogActions)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 0.2rem;
`;
