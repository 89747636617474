import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'hooks/translation';
import { yup } from 'services/yup';
import { TextFieldForm } from 'components/Forms/TextFieldForm';
import { useForm } from 'hooks/form';
import { CurrencyInput } from 'components/Forms/CurrencyInput';
import { Button } from 'components/Button';
import {
  apiErrorHandler,
  formatAmountToServer,
  formatDateToServer,
  getI18nNamespace,
} from 'utils/helpers';
import { formatCurrency, formatCurrencyToNumber } from 'utils/currency';
import { Dropdown } from 'components/Forms/Dropdown';
import { Dispatch, RootState } from 'store';
import { updateEmployeeById } from 'repositories/employees';
import { createAutomatedBenefit } from 'repositories/companies';
import { DropdownTextField } from 'components/Forms/DropdownTextField';
import { useCompany } from 'hooks/company';
import { isApiError } from 'utils/errors';
import { BookingReasons } from 'utils/api';
import { useBenefitBook } from '../../useBenefitBook';

import { Footer, DialogContent } from '../../BenefitBookDialog.styled';
import { benefitBookingErrorHandler } from '../../benefitBookingErrorHandler';

interface DigitalBenefitForm {
  amount: string;
  description: string;
  email: string;
  emailConfirmation: string;
  networkId: string;
  deliveryFormat: Exclude<DeliveryType, 'PLASTICCARD'> | '';
}

interface DigitalBenefitFormData extends DigitalBenefitForm {
  deliveryFormat: Exclude<DeliveryType, 'PLASTICCARD'>;
}

const initialValues: DigitalBenefitForm = {
  amount: '',
  description: '',
  email: '',
  emailConfirmation: '',
  networkId: '',
  deliveryFormat: '',
};

const companyTranslator = getI18nNamespace('company');
const formTranslator = getI18nNamespace('form');

const digitalBenefitValidationSchema = {
  description: yup
    .string()
    .label(
      companyTranslator(
        'benefits.benefitBookDialog.digitalBenefit.descriptionLabel',
      ),
    ),
  email: yup.string().test(
    'is-delivery-format-email',
    formTranslator('validation.requiredField', {
      field: companyTranslator(
        'benefits.benefitBookDialog.digitalBenefit.emailLabel',
      ),
    }),
    (value: string | undefined, context) => {
      const { deliveryFormat } = context.parent;

      if (deliveryFormat === 'EMAIL') return Boolean(value);

      return true;
    },
  ),
  emailConfirmation: yup
    .string()
    .label(
      companyTranslator(
        'benefits.benefitBookDialog.digitalBenefit.confirmEmailLabel',
      ),
    )
    .test(
      'is-delivery-format-email',
      formTranslator('validation.requiredField', {
        field: companyTranslator(
          'benefits.benefitBookDialog.digitalBenefit.confirmEmailLabel',
        ),
      }),
      (value: string | undefined, context) => {
        const { deliveryFormat } = context.parent;

        if (deliveryFormat === 'EMAIL') return Boolean(value);

        return true;
      },
    )
    .oneOf(
      [yup.ref('email')],
      formTranslator('validation.mustBeEqual', {
        field: companyTranslator(
          'benefits.benefitBookDialog.digitalBenefit.confirmEmailLabel',
        ),
        fieldEqual: companyTranslator(
          'benefits.benefitBookDialog.digitalBenefit.emailLabel',
        ),
      }),
    ),
  networkId: yup
    .string()
    .label(
      companyTranslator(
        'benefits.benefitBookDialog.digitalBenefit.networkLabel',
      ),
    )
    .required(),
  deliveryFormat: yup
    .string()
    .label(
      companyTranslator(
        'benefits.benefitBookDialog.digitalBenefit.deliveryFormatLabel',
      ),
    )
    .required(),
};

const formatDropdownOptions: DropdownOption<DeliveryType>[] = [
  {
    label: companyTranslator(
      'benefits.benefitBookDialog.digitalBenefit.pdfDeliveryFormatOption',
    ),
    value: 'PDF',
  },
  {
    label: companyTranslator(
      'benefits.benefitBookDialog.digitalBenefit.emailDeliveryFormatOption',
    ),
    value: 'EMAIL',
  },
];

interface Props {
  isSwitchGiftcard: boolean;
}

export const DigitalBenefit = (props: Props) => {
  const { isSwitchGiftcard } = props;

  const {
    benefit,
    employee,
    bookType,
    automatedBenefitData,
    giftcardData,
    changeEmployee,
    changeGiftcardData,
    changeDeliveryType: changeDeliveryFormat,
    goNext,
  } = useBenefitBook();
  const { t } = useTranslation('company');
  const dispatch = useDispatch<Dispatch>();
  const deliveryFormatPreferences = useSelector(
    (state: RootState) => state.preferences.benefitBook.deliveryFormat,
  );
  const { company } = useCompany();

  const hasEmail = Boolean(employee?.email);
  const isPrasentBenefit = benefit?.benefitTypeId === 'de:praesent';

  const networkOptions = useMemo<DropdownOption<string>[]>(() => {
    if (!company) return [];

    return company.resolvedNetworks.map(network => ({
      label: network.networkName,
      value: network.id,
    }));
  }, [company?.resolvedNetworks]);

  const allowedAmount = benefit ? benefit.allowedBenefitAmount / 100 : 0;
  const isAutomatedBenefit = bookType === 'automated';

  const validationSchema = useMemo(() => {
    if (isAutomatedBenefit) {
      return yup.object().shape({
        ...digitalBenefitValidationSchema,
        networkId: isSwitchGiftcard
          ? digitalBenefitValidationSchema.networkId.notRequired()
          : digitalBenefitValidationSchema.networkId,
        amount: yup
          .string()
          .label(
            t('benefits.benefitBookDialog.digitalBenefit.amountLabel', {
              disableParentElement: true,
            }),
          )
          .minCurrencyAmount(1)
          .required(),
      });
    }

    return yup.object().shape({
      ...digitalBenefitValidationSchema,
      amount: yup
        .string()
        .label(
          t('benefits.benefitBookDialog.digitalBenefit.amountLabel', {
            disableParentElement: true,
          }),
        )
        .minCurrencyAmount(1)
        .maxCurrencyAmount(allowedAmount)
        .required(),
    });
  }, [allowedAmount, isAutomatedBenefit, isSwitchGiftcard]);

  const onSubmit = async (formData: DigitalBenefitFormData) => {
    try {
      const network = company?.resolvedNetworks.find(findNetwork => {
        if (isSwitchGiftcard && giftcardData) {
          return findNetwork.id === giftcardData.networkId;
        }

        return findNetwork.id === formData.networkId;
      });

      if (!network || !employee) return;

      const { deliveryFormat, networkId } = formData;

      if (!hasEmail && deliveryFormat === 'EMAIL') {
        const updatedEmployee = await updateEmployeeById({
          employeeId: employee.id,
          name: employee.name,
          email: formData.email,
          phone: employee.phone,
          staffNumber: employee.staffNumber,
        });

        if (!updatedEmployee) return;

        await changeEmployee(updatedEmployee);
      }

      const amount = formatCurrencyToNumber(formData.amount);

      const shouldSimulate = [
        bookType === 'automated',
        !isSwitchGiftcard,
      ].every(Boolean);

      if (shouldSimulate && automatedBenefitData) {
        const { startsAt, expiresAt, interval } = automatedBenefitData;

        const benefitTransaction = await createAutomatedBenefit({
          employeeId: employee.id,
          networkId,
          frequency: interval,
          amount: formatAmountToServer(amount),
          benefitTypeId: String(benefit?.benefitTypeId),
          deliveryType: deliveryFormat,
          startsAt: formatDateToServer(startsAt),
          expiresAt: formatDateToServer(expiresAt),
          simulate: true,
        });

        if (!benefitTransaction.success) {
          const message = benefitBookingErrorHandler(benefitTransaction.reason);

          dispatch.alert.OPEN_ALERT_DIALOG({
            alertDialogMessage: message,
            alertDialogType: 'error',
          });

          return;
        }
      }

      dispatch.preferences.setBenefitBook({ deliveryFormat });

      changeDeliveryFormat(deliveryFormat);

      if (isSwitchGiftcard && giftcardData) {
        const benefitNetwork = company?.resolvedNetworks.find(
          findNetwork => findNetwork.id === giftcardData.networkId,
        );

        if (!benefitNetwork) return;

        changeGiftcardData({
          ...giftcardData,
          description: formData.description || undefined,
          networkName: benefitNetwork.networkName,
          networkId: benefitNetwork.id,
        });

        return goNext();
      }

      changeGiftcardData({
        amount,
        description: formData.description || undefined,
        formattedAmount: formatCurrency(amount, 0),
        networkName: network.networkName,
        networkId,
      });

      goNext();
    } catch (error) {
      if (isApiError(error)) {
        const errorId = error.response?.data.errorId;

        if (errorId === BookingReasons.INVALID_DATE_RANGE) {
          const message = benefitBookingErrorHandler(errorId);

          dispatch.alert.OPEN_ALERT_DIALOG({
            alertDialogMessage: message,
            alertDialogType: 'error',
          });

          return;
        }
      }

      apiErrorHandler(error, dispatch);
    }
  };

  const {
    handleSubmit,
    getFieldProps,
    setValues,
    setValue,
    isSubmitting,
    values,
  } = useForm<DigitalBenefitForm>({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const isEmailDeliveryFormat = values.deliveryFormat === 'EMAIL';

  useEffect(() => {
    if (!employee || !company) return;

    const _networkId =
      company.resolvedNetworks.length === 1
        ? company.resolvedNetworks[0].id
        : initialValues.networkId;

    const networkId =
      isSwitchGiftcard && giftcardData ? giftcardData.networkId : _networkId;

    const getDeliveryFormat = (): Exclude<DeliveryType, 'PLASTICCARD'> | '' => {
      if (deliveryFormatPreferences === 'PLASTICCARD') return '';

      return deliveryFormatPreferences ?? initialValues.deliveryFormat;
    };

    const amount =
      isSwitchGiftcard && giftcardData
        ? giftcardData.formattedAmount
        : initialValues.amount;

    setValues({
      amount,
      description: initialValues.description,
      email: employee?.email ?? initialValues.email,
      emailConfirmation: employee?.email ?? initialValues.emailConfirmation,
      networkId,
      deliveryFormat: getDeliveryFormat(),
    });
  }, [
    company?.id,
    employee?.email,
    deliveryFormatPreferences,
    isAutomatedBenefit,
    isSwitchGiftcard,
  ]);

  const getDescriptionOptions = (): DropdownOption<string>[] => {
    const descriptionOptions = t(
      'benefits.benefitBookDialog.dePrasentDescriptions',
      {
        returnObjects: true,
        disableParentElement: true,
      },
    );

    const options = descriptionOptions.map<DropdownOption<string>>(option => ({
      label: option,
      value: option,
    }));

    const ownReason = t(
      'benefits.benefitBookDialog.dePrasentOwnReasonDescription',
      {
        disableParentElement: true,
      },
    );

    options.push({ label: ownReason, value: initialValues.description });

    return options;
  };

  const handleSelectDescription = (option: DropdownOption<string> | null) => {
    if (option && option?.value === initialValues.description) {
      setValue('description', initialValues.description);
      return;
    }

    setValue('description', option?.value ?? initialValues.description);
  };

  return (
    <DialogContent data-testid="benefit-book-digital-benefit">
      <form onSubmit={handleSubmit} noValidate>
        <CurrencyInput
          {...getFieldProps('amount')}
          label={t('benefits.benefitBookDialog.digitalBenefit.amountLabel')}
          variant="standard"
          required
          autoFocus
          fullWidth
          inputProps={{
            'data-testid': 'amount-input',
          }}
          disabled={isSwitchGiftcard}
        />

        {isPrasentBenefit && (
          <DropdownTextField
            {...getFieldProps('description')}
            onSelectOption={handleSelectDescription}
            options={getDescriptionOptions()}
            label={t(
              'benefits.benefitBookDialog.digitalBenefit.descriptionLabel',
            )}
            inputProps={{
              'data-testid': 'description-input',
            }}
          />
        )}

        {!isSwitchGiftcard && (
          <Dropdown
            {...getFieldProps('networkId')}
            options={networkOptions}
            label={t('benefits.benefitBookDialog.digitalBenefit.networkLabel', {
              disableParentElement: true,
            })}
            inputProps={{
              'data-testid': 'network-dropdown',
            }}
          />
        )}

        <Dropdown
          {...getFieldProps('deliveryFormat')}
          options={formatDropdownOptions}
          label={t(
            'benefits.benefitBookDialog.digitalBenefit.deliveryFormatLabel',
            { disableParentElement: true },
          )}
          inputProps={{
            'data-testid': 'format-dropdown',
          }}
        />

        {isEmailDeliveryFormat && (
          <>
            <TextFieldForm
              {...getFieldProps('email')}
              label={t('benefits.benefitBookDialog.digitalBenefit.emailLabel')}
              autoComplete="email"
              type="email"
              required={!hasEmail}
              inputProps={{
                'data-testid': 'email-input',
              }}
              disabled={hasEmail}
            />

            {!hasEmail && (
              <TextFieldForm
                {...getFieldProps('emailConfirmation')}
                label={t(
                  'benefits.benefitBookDialog.digitalBenefit.confirmEmailLabel',
                )}
                autoComplete="email"
                type="email"
                required
                inputProps={{
                  'data-testid': 'email-confirmation-input',
                }}
              />
            )}
          </>
        )}

        <Footer>
          <Button
            type="submit"
            fullWidth
            data-testid="digital-benefit-next-button"
            isLoading={isSubmitting}
          >
            {t('benefits.benefitBookDialog.nextButton')}
          </Button>
        </Footer>
      </form>
    </DialogContent>
  );
};
