import urlcat from 'urlcat';
import { AxiosProgressEvent } from 'axios';
import { api } from 'services/api';
import { EmployeeEndpoints } from 'utils/endpoints';
import { automatedBenefitAdapter } from 'adapters/zmyle/automatedBenefit/automatedBenefit';

export interface CreateEmployeeParams {
  companyId: string;
  name: string;
  email?: string;
  staffNumber?: string;
  costCenter?: string;
  phone?: string;
}

export const createEmployee = async (params: CreateEmployeeParams) => {
  const body = {
    ...params,
    email: params.email || null,
    staffNumber: params.staffNumber || null,
    phone: params.phone || null,
  };

  const response = await api.post<Employee | undefined>(
    EmployeeEndpoints.EMPLOYEES,
    body,
  );

  return response.data;
};

export const getEmployeeById = async (id: string) => {
  const url = urlcat(EmployeeEndpoints.GET_EMPLOYEE, {
    employeeId: id,
  });

  const response = await api.get<Employee | undefined>(url);

  return response.data;
};

export const getAutomatedBenefitsByEmployeeId = async (
  employeeId: string,
): Promise<Paginated<AdaptedAutomatedBenefit>> => {
  const url = urlcat(EmployeeEndpoints.GET_AUTOMATED_BENEFITS, {
    employeeId,
  });

  const response = await api.get<Paginated<AutomatedBenefit>>(url);

  const adaptedAutomatedBenefits = response.data?.items.map(
    automatedBenefitAdapter,
  );

  return { ...response.data, items: adaptedAutomatedBenefits };
};

export interface UpdateEmployeeByIdParams {
  employeeId: string;
  name?: string;
  email?: string;
  staffNumber?: string;
  costCenter?: string;
  phone?: string;
}

export const updateEmployeeById = async (params: UpdateEmployeeByIdParams) => {
  const { employeeId, name, email, phone, staffNumber, costCenter } = params;

  const url = urlcat(EmployeeEndpoints.UPDATE_EMPLOYEE, {
    employeeId,
  });

  const body = {
    name,
    email: email || null,
    phone: phone || null,
    staffNumber: staffNumber || null,
    costCenter: costCenter || null,
  };

  const response = await api.put<Employee | undefined>(url, body);

  return response.data;
};

export const deleteEmployeeById = async (employee: Employee) => {
  const { id } = employee;

  const url = urlcat(EmployeeEndpoints.UPDATE_EMPLOYEE, {
    employeeId: id,
  });

  const response = await api.delete(url);
  return response.data;
};

interface ReadEmployeesFileParams {
  file: File;
  onUploadProgress?(progressEvent: AxiosProgressEvent): void;
}

interface ReadEmployeesFileResponse {
  data: Array<string[]>;
  detectedHeader: boolean;
  fileKey: string;
}

export const readEmployeesFile = async (params: ReadEmployeesFileParams) => {
  const { file, onUploadProgress } = params;

  const data = new FormData();

  data.append('file', file);

  const response = await api.post<ReadEmployeesFileResponse>(
    EmployeeEndpoints.EMPLOYEES_IMPORT,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    },
  );

  return response.data;
};

export interface ResolveFileImportingParams {
  companyId: string;
  fileKey: string;
  fileContainsHeader: boolean;
  employeeMap: Partial<Record<keyof Employee, number>>;
}

interface ResolveFileImportingResponse {
  importedCount: number;
  errors: Array<{
    error: { description: string; errorId: string };
    lineNo: number;
  }>;
}

export const resolveFileImporting = async (
  params: ResolveFileImportingParams,
) => {
  const response = await api.post<ResolveFileImportingResponse>(
    EmployeeEndpoints.RESOLVE_FILE_IMPORTING,
    params,
  );

  return response.data;
};

export const getEmployeeAllowances = async (employeeId: string) => {
  const url = urlcat(EmployeeEndpoints.GET_BENEFIT_ALLOWANCES, { employeeId });

  const response = await api.get<Benefit[]>(url);

  return response.data;
};
