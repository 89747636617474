import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from 'styles/defaultTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > div:last-child {
    margin-top: 7.687rem;
  }

  ${MOBILE_BREAKPOINT} {
    > div:last-child {
      margin-top: auto;
    }
  }
`;
