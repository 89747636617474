import urlcat from 'urlcat';
import { api } from 'services/api';
import { RetailerEndpoints } from 'utils/endpoints';
import {
  apiTenantAdapter,
  invoiceAdapter,
  miniGiftcardAdapter,
  giftcardTransactionAdapter,
  partnerApiKeyAdapter,
} from 'adapters';

interface CreateRetailerParams {
  businessName: string;
  addressStreet: string;
  addressCity: string;
  addressZipcode: string;
  store: {
    category: string;
    networkId: string;
  };
  userId: string;
}

export const createRetailer = async (params: CreateRetailerParams) => {
  const response = await api.post<Retailer>(
    RetailerEndpoints.CREATE_RETAILER,
    params,
  );

  return response.data;
};

export interface UpdateRetailerParams {
  retailerId: string;
  name: string;
  name2: string | undefined;
  name3: string | undefined;
  email: string;
  bankAccount: string | undefined;
  approvedSepaDirectDebitAt: string | undefined;
  phone?: string;
  vatId?: string;
  pincode?: string;
  addressCity: string;
  addressZipcode: string;
  addressStreet: string;
  addressStreet2?: string;
  addressCountry?: string;
}

export const updateRetailer = async (params: UpdateRetailerParams) => {
  const { retailerId, pincode, ...contact } = params;

  const url = urlcat(RetailerEndpoints.UPDATE_RETAILER, { retailerId });

  const response = await api.put<Retailer>(url, { contact, pincode });

  return response.data;
};

export interface UpdateRetailerSettingsParams {
  retailerId: string;
  pincode: string;
  subscribedGiftcardTransactionSlips: boolean;
  enablePromotionGiftcards: boolean;
  enableOfflineGiftcards: boolean;
}

export const updateRetailerSettings = async (
  params: UpdateRetailerSettingsParams,
) => {
  const { retailerId, ...requestBody } = params;

  const url = urlcat(RetailerEndpoints.UPDATE_RETAILER_SETTINGS, {
    retailerId,
  });

  const response = await api.put<Retailer>(url, requestBody);

  return response.data;
};

export const getRetailerById = async (retailerId: string) => {
  const url = urlcat(RetailerEndpoints.GET_RETAILER, {
    retailerId,
  });

  const response = await api.get<Retailer>(url, {
    params: { includeStores: true },
  });

  return response.data;
};

export const getTeamMembersByRetailerId = async (retailerId: string) => {
  const url = urlcat(RetailerEndpoints.GET_TEAM_MEMBERS, { retailerId });

  const response = await api.get<TeamMember[]>(url);

  return response.data;
};

export interface CreateTeamMemberParams {
  retailerId: string;
  email: string;
  authRole: AuthRole;
}

export const createTeamMember = async (params: CreateTeamMemberParams) => {
  const { retailerId, ...body } = params;

  const url = urlcat(RetailerEndpoints.CREATE_TEAM_MEMBER, {
    retailerId,
  });

  const response = await api.post(url, body);

  return response.data;
};

interface DeleteTeamMemberParams {
  retailerId: string;
  userId: string;
}

export const deleteTeamMember = async (params: DeleteTeamMemberParams) => {
  const { retailerId, userId } = params;

  const url = urlcat(RetailerEndpoints.DELETE_TEAM_MEMBER, {
    retailerId,
    userId,
  });

  const response = await api.delete(url);

  return response.data;
};

interface UpdateTeamMemberParams {
  retailerId: string;
  userId: string;
  authRole: AuthRole;
}

export const updateTeamMember = async (params: UpdateTeamMemberParams) => {
  const { retailerId, userId, ...body } = params;

  const url = urlcat(RetailerEndpoints.UPDATE_TEAM_MEMBER, {
    retailerId,
    userId,
  });

  const response = await api.put(url, body);

  return response.data;
};

interface GetRetailerGiftcardTransactions {
  retailerId: string;
  page?: number;
  pageSize?: number;
  fromDate?: string;
  toDate?: string;
  giftcardTypes?: string;
  transactionTypes?: string;
  query?: string;
}

export const getRetailerGiftcardTransactions = async (
  params: GetRetailerGiftcardTransactions,
) => {
  const { retailerId, ...requestParams } = params;

  const url = urlcat(RetailerEndpoints.GET_GIFTCARD_TRANSACTIONS, {
    retailerId,
  });

  const response = await api.get<Paginated<GiftcardTransaction>>(url, {
    params: requestParams,
  });

  return {
    ...response.data,
    items: response.data.items.map(giftcardTransactionAdapter),
  };
};

interface GetRetailerTransactions {
  retailerId: string;
  page?: number;
  pageSize?: number;
  fromDate?: string;
  toDate?: string;
}

export const getRetailerTransactions = async (
  params: GetRetailerTransactions,
) => {
  const { retailerId, ...requestParams } = params;

  const url = urlcat(RetailerEndpoints.GET_RETAILER_TRANSACTIONS, {
    retailerId,
  });

  const response = await api.get<Paginated<MiniGiftcard>>(url, {
    params: requestParams,
  });

  return {
    ...response.data,
    items: response.data.items.map(miniGiftcardAdapter),
  };
};

interface SearchRetailerTransaction {
  retailerId: string;
  shortId: string;
  includeCampaigns?: boolean;
}
export const searchRetailerTransaction = async (
  params: SearchRetailerTransaction,
) => {
  const { retailerId, ...requestParams } = params;

  const url = urlcat(RetailerEndpoints.SEARCH_RETAILER_TRANSACTION, {
    retailerId,
  });

  const response = await api.get<MiniGiftcard[]>(url, {
    params: requestParams,
  });

  const adaptedGiftcards = response.data.map(miniGiftcardAdapter);

  return adaptedGiftcards;
};

interface GetRetailerStoresParams {
  retailerId: string;
  page?: number;
  pageSize?: number;
}

export const getRetailerStores = async (params: GetRetailerStoresParams) => {
  const { retailerId, ...requestParams } = params;

  const url = urlcat(RetailerEndpoints.GET_STORES, { retailerId });

  const response = await api.get<Paginated<Store>>(url, {
    params: requestParams,
  });

  return response.data;
};

interface GetRetailerInvoicesParams {
  retailerId: string;
  page?: number;
  pageSize?: number;
}

export const getRetailerInvoices = async (
  params: GetRetailerInvoicesParams,
): Promise<Paginated<AdaptedInvoice>> => {
  const { retailerId, ...requestParams } = params;

  const url = urlcat(RetailerEndpoints.GET_INVOICES, { retailerId });

  const response = await api.get<Paginated<Invoice>>(url, {
    params: requestParams,
  });

  const adaptedInvoices = response.data.items.map(invoiceAdapter);

  return { ...response.data, items: adaptedInvoices };
};

interface CreateRetailerGiftcardParams {
  retailerId: string;
  amount: number;
  receiptNumber?: string;
  idempotencyKey: string;
}

export const createRetailerGiftcard = async (
  params: CreateRetailerGiftcardParams,
) => {
  const { retailerId, amount, receiptNumber, idempotencyKey } = params;

  const url = urlcat(RetailerEndpoints.CREATE_GIFTCARD, { retailerId });

  const response = await api.post<Giftcard>(url, {
    amount,
    receiptNumber,
    idempotencyKey,
  });

  return response.data;
};

export const getApiTenantsByRetailerId = async (retailerId: string) => {
  const url = urlcat(RetailerEndpoints.GET_API_KEYS, { retailerId });

  const response = await api.get<ApiTenant[]>(url);

  const adaptedApiTenants = response.data.map(apiTenantAdapter);

  return adaptedApiTenants;
};

export const getPartnerApiKeysRetailerId = async (retailerId: string) => {
  const url = urlcat(RetailerEndpoints.GET_PARTNER_API_KEYS, { retailerId });

  const response = await api.get<PartnerApiKey[]>(url);

  const adaptedApiTenants = response.data.map(partnerApiKeyAdapter);

  return adaptedApiTenants;
};

interface CreateRetailerApiTenantParams {
  retailerId: string;
  name: string;
}

export const createRetailerApiTenant = async (
  params: CreateRetailerApiTenantParams,
) => {
  const { retailerId, name } = params;

  const url = urlcat(RetailerEndpoints.CREATE_API_KEY, { retailerId });

  const response = await api.post<ApiTenant>(url, { name });

  const adaptedApiTenant = apiTenantAdapter(response.data);

  return adaptedApiTenant;
};

interface CreateRetailerPartnerApiKeyParams {
  retailerId: string;
  name: string;
}

export const createRetailerPartnerApiKey = async (
  params: CreateRetailerPartnerApiKeyParams,
) => {
  const { retailerId, name } = params;

  const url = urlcat(RetailerEndpoints.CREATE_PARTNER_API_KEYS, { retailerId });

  const response = await api.post<PartnerApiKey>(url, { name });

  const adaptedApiTenant = partnerApiKeyAdapter(response.data);

  return adaptedApiTenant;
};

interface GetRetailerApiTenantParams {
  retailerId: string;
  apiTenantId: string;
}

export const getRetailerApiTenant = async (
  params: GetRetailerApiTenantParams,
) => {
  const { retailerId, apiTenantId } = params;

  const url = urlcat(RetailerEndpoints.GET_API_KEY, {
    retailerId,
    api_tenant_id: apiTenantId,
  });

  const response = await api.get<ApiTenant>(url);

  const adaptedApiTenant = apiTenantAdapter(response.data);

  return adaptedApiTenant;
};

interface GetRetailerPartnerApiKeyParams {
  retailerId: string;
  partnerKeyId: string;
}

export const getRetailerPartnerApiKey = async (
  params: GetRetailerPartnerApiKeyParams,
) => {
  const { retailerId, partnerKeyId } = params;

  const url = urlcat(RetailerEndpoints.GET_PARTNER_API_KEY, {
    retailerId,
    partnerKeyId,
  });

  const response = await api.get<PartnerApiKey>(url);

  const adaptedApiTenant = partnerApiKeyAdapter(response.data);

  return adaptedApiTenant;
};

interface DeleteRetailerApiTenantParams {
  retailerId: string;
  apiTenantId: string;
}

export const deleteRetailerApiTenant = async (
  params: DeleteRetailerApiTenantParams,
) => {
  const { retailerId, apiTenantId } = params;

  const url = urlcat(RetailerEndpoints.DELETE_API_KEY, {
    retailerId,
    api_tenant_id: apiTenantId,
  });

  const response = await api.delete(url);

  return response.data;
};

interface DeleteRetailerPartnerApiKeyParams {
  retailerId: string;
  partnerKeyId: string;
}

export const deleteRetailerPartnerApiKey = async (
  params: DeleteRetailerPartnerApiKeyParams,
) => {
  const { retailerId, partnerKeyId } = params;

  const url = urlcat(RetailerEndpoints.DELETE_PARTNER_API_KEY, {
    retailerId,
    partnerKeyId,
  });

  const response = await api.delete(url);

  return response.data;
};

interface CreateRetailerAppleWalletPicture {
  retailerId: string;
  picture?: string;
}

export const createRetailerAppleWalletPicture = async (
  params: CreateRetailerAppleWalletPicture,
) => {
  const { retailerId, picture } = params;

  const url = urlcat(RetailerEndpoints.CREATE_RETAILER_APPLE_WALLET_PICTURE, {
    retailerId,
  });

  const response = await api.post(
    url,
    { picture },
    {
      timeout: 1000 * 180, // 3min
    },
  );

  return response.data;
};

interface CreateRetailerGoogleWalletPicture {
  retailerId: string;
  picture?: string;
}

export const createRetailerGoogleWalletPicture = async (
  params: CreateRetailerGoogleWalletPicture,
) => {
  const { retailerId, picture } = params;

  const url = urlcat(RetailerEndpoints.CREATE_RETAILER_GOOGLE_WALLET_PICTURE, {
    retailerId,
  });

  const response = await api.post(
    url,
    { picture },
    {
      timeout: 1000 * 180, // 3min
    },
  );

  return response.data;
};

interface CreateRetailerGiftcardPdfPicture {
  retailerId: string;
  picture?: string;
}

export const createRetailerGiftcardPdfPicture = async (
  params: CreateRetailerGiftcardPdfPicture,
) => {
  const { retailerId, picture } = params;

  const url = urlcat(RetailerEndpoints.CREATE_RETAILER_GIFTCARD_PDF_PICTURE, {
    retailerId,
  });

  const response = await api.post(
    url,
    { picture },
    {
      timeout: 1000 * 180, // 3min
    },
  );

  return response.data;
};
