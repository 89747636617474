/* eslint-disable func-names */
import { TFunction, Namespace } from 'i18next';
import { formatDateToApp, getI18nNamespace } from 'utils/helpers';
import { formatCurrency } from 'utils/currency';
import * as yup from 'yup';

interface GenericLocaleParams {
  path: string;
}

interface MinLocaleParams extends GenericLocaleParams {
  min: number;
}

interface MinDateLocaleParams extends GenericLocaleParams {
  min: Date;
}

interface MaxDateLocaleParams extends GenericLocaleParams {
  max: Date;
}

interface NotTypeLocaleParams extends GenericLocaleParams {
  type: 'date' | 'string' | 'number';
}

export const buildYupLocale = (_: unknown, t: TFunction<Namespace>) => {
  yup.setLocale({
    mixed: {
      required: (data: GenericLocaleParams) => {
        const { path } = data;

        return t('validation.requiredField', {
          field: path,
          ns: 'form',
        });
      },
      notType: (data: NotTypeLocaleParams) => {
        const { path, type } = data;

        if (type === 'date') {
          return t('validation.mustBeAValidDate', {
            field: path,
            ns: 'form',
          });
        }

        return t('validation.mustBeAValidValue', {
          field: path,
          ns: 'form',
        });
      },
    },
    string: {
      email: (data: GenericLocaleParams) => {
        const { path } = data;

        return t('validation.mustBeValidEmail', {
          field: path,
          ns: 'form',
        });
      },
      min: (data: MinLocaleParams) => {
        const { path, min } = data;

        return t('validation.mustBeAtLeastChars', {
          field: path,
          quantity: min,
          ns: 'form',
        });
      },
    },
    date: {
      min: (data: MinDateLocaleParams) => {
        const { min, path } = data;

        return t('validation.mustBeLaterThan', {
          field: path,
          date: formatDateToApp(min),
          ns: 'form',
        });
      },
      max: (data: MaxDateLocaleParams) => {
        const { max, path } = data;

        return t('validation.mustBeBeforeThan', {
          field: path,
          date: formatDateToApp(max),
          ns: 'form',
        });
      },
    },
  });
};

yup.addMethod(yup.string, 'maxCurrencyAmount', function (amount: number) {
  return this.test({
    message: (data: GenericLocaleParams) => {
      const { path } = data;

      return getI18nNamespace('form')('validation.mustBeLessOrEqual', {
        field: path,
        amount: formatCurrency(amount, 0),
      });
    },
    test: value => {
      const parsed = Number(value?.replace(/€|\./g, '').replace(',', '.'));

      return parsed <= amount;
    },
  });
});

yup.addMethod(yup.string, 'minCurrencyAmount', function (amount: number) {
  return this.test({
    message: (data: GenericLocaleParams) => {
      const { path } = data;

      return getI18nNamespace('form')('validation.mustBeGreaterOrEqual', {
        field: path,
        amount: formatCurrency(amount, 0),
      });
    },
    test: value => {
      const parsed = Number(value?.replace(/€|\./g, '').replace(',', '.'));

      return parsed >= amount;
    },
  });
});

export { yup };
