import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Title = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.375rem;
  margin-top: 0.5rem;

  svg {
    margin-left: 0.5rem;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  min-height: 12rem;
  width: 25.75rem;
  max-width: 100%;

  border-radius: 0.25rem;

  padding: 1.188rem 0.5rem 2.5rem 1.12rem;
  margin: 1.5rem 0 1.25rem 0;

  background-color: ${({ theme }) => theme.palette.zml.grey50};
  color: ${({ theme }) => theme.palette.zml.blue800};
  box-shadow: 0 0.188rem 0.375rem
    ${({ theme }) => theme.palette.zml.black14Opacity};

  svg {
    color: ${({ theme }) => theme.palette.zml.pink700};
  }
`;

export const Description = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-left: 1.25rem;

  strong {
    font-size: 1.063rem;
    text-align: left;
  }

  p {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    text-align: left;
  }
`;

export const LinkInfoBusinessAccount = styled(Link)`
  margin-top: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};

  :hover {
    text-decoration: underline;
  }
`;
