import { ButtonProps, CircularProgress } from '@mui/material';
import { useRef } from 'react';
import { IconType } from 'react-icons';

import { ButtonStyled, IconStyled } from './Button.styled';

interface Props extends ButtonProps {
  isLoading?: boolean;
  Icon?: IconType;
  target?: string;
  bgColor?: keyof ZmyleThemeColors;
}

export const Button = (props: Props) => {
  const {
    isLoading = false,
    children,
    color = 'primary',
    variant = 'contained',
    size = 'medium',
    Icon: icon,
    bgColor,
    ...rest
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const isLoadingProps: ButtonProps = isLoading
    ? {
        disabled: true,
        style: {
          minWidth: buttonRef.current?.clientWidth,
          minHeight: buttonRef.current?.clientHeight,
        },
      }
    : {};

  return (
    <ButtonStyled
      $bgColor={bgColor}
      color={color}
      variant={variant}
      size={size}
      {...rest}
      {...isLoadingProps}
      ref={buttonRef}
    >
      <>
        {icon && <IconStyled icon={icon} size={17} />}

        {isLoading ? (
          <CircularProgress
            color="inherit"
            size="1.5rem"
            data-testid="button-circular-progress"
          />
        ) : (
          <span>{children}</span>
        )}
      </>
    </ButtonStyled>
  );
};
