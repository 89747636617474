import { TextFieldForm, TextFieldFormProps } from '../TextFieldForm';
import { MaskedCurrencyInput } from './MaskedCurrencyInput';

export const CurrencyInput = (props: TextFieldFormProps) => {
  return (
    <TextFieldForm
      {...props}
      InputProps={{ inputComponent: MaskedCurrencyInput }}
    />
  );
};
