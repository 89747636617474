import { Suspense, useEffect } from 'react';
import {
  Route,
  RouteProps,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { lazyWithRetry } from 'utils/lazyWithRetry';
import { RootState } from 'store';
import paths from 'utils/paths';
import { SuspenseLoading } from 'components/LoadingScreen';
import { useCompany } from 'hooks/company';
import { RedirectNotFound } from './RedirectNotFound';

const Transactions = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "company-transactions" */ 'pages/Benefits/Transactions'
    ),
);

const TransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "transaction-details" */ 'pages/Benefits/TransactionDetails'
    ),
);

const EmployeesImport = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "employees-import" */ 'pages/Benefits/EmployeesImport'
    ),
);

const Employees = lazyWithRetry(
  () => import(/* webpackChunkName: "employees" */ 'pages/Benefits/Employees'),
);

const Services = lazyWithRetry(
  () => import(/* webpackChunkName: "downloads" */ 'pages/Benefits/Services'),
);

const GiftcardDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "giftcard-details" */ 'pages/Benefits/GiftcardDetails'
    ),
);

const CompanyDashboard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "company-dashboard" */ 'pages/Benefits/CompanyDashboard'
    ),
);
const YourCompany = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "your-company" */ 'pages/Benefits/YourCompany'),
);

const EmployeeUpdate = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "employee-update" */ 'pages/Benefits/EmployeeUpdate'
    ),
);

const AutomatedBenefitDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "automated-benefit-details" */ 'pages/Benefits/AutomatedBenefitDetails'
    ),
);

const Team = lazyWithRetry(
  () => import(/* webpackChunkName: "team" */ 'pages/Benefits/Team'),
);

const TeamMemberDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "team-member-details" */ 'pages/Benefits/TeamMemberDetails'
    ),
);

const Settings = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "company-settings" */ 'pages/Benefits/Settings'
    ),
);

interface RouteItem extends RouteProps {
  key: string;
}

const managerRoutes: RouteItem[] = [
  {
    key: 'company-team',
    path: paths.COMPANY + paths.TEAM,
    component: Team,
    exact: true,
  },
  {
    key: 'company-team-member-details',
    path: paths.COMPANY + paths.TEAM_MEMBER,
    component: TeamMemberDetails,
    exact: true,
  },
  {
    key: 'company-settings',
    path: paths.COMPANY + paths.SETTINGS,
    component: Settings,
    exact: true,
  },
];

const renderLoading = () => <SuspenseLoading />;

interface Params {
  companyId?: string;
}

export const CompanyRoutes = () => {
  const params = useParams<Params>();
  const history = useHistory();

  const isAppInitialized = useSelector(
    (state: RootState) => state.app.isInitialized,
  );
  const { company, companies, companyActions } = useCompany();

  const shouldRenderManagerRoutes = company?.authRole === 'MANAGER';
  const shouldRenderAccountantRoutes = company?.authRole === 'ACCOUNTANT';

  useEffect(() => {
    if (!isAppInitialized) return;

    if (!params?.companyId || company?.id === params?.companyId) return;

    const _company = companies.find(
      findCompany => findCompany.id === params.companyId,
    );

    if (!_company) return history.replace(paths.DASHBOARD);

    companyActions.SET_CURRENT_COMPANY(_company);
  }, [params?.companyId, isAppInitialized]);

  return (
    <Suspense fallback={renderLoading()}>
      <Switch>
        <Route
          path={paths.COMPANY + paths.DASHBOARD}
          component={CompanyDashboard}
          exact
        />
        <Route
          path={paths.COMPANY + paths.TRANSACTIONS}
          component={Transactions}
          exact
        />
        <Route
          path={paths.COMPANY + paths.TRANSACTION_DETAILS}
          component={TransactionDetails}
          exact
        />
        <Route
          path={paths.COMPANY + paths.AUTOMATED_BENEFIT_DETAILS}
          component={AutomatedBenefitDetails}
          exact
        />
        <Route
          path={paths.COMPANY + paths.EMPLOYEES}
          component={Employees}
          exact
        />
        <Route
          path={paths.COMPANY + paths.EMPLOYEES + paths.IMPORT}
          component={EmployeesImport}
          exact
        />
        <Route
          path={paths.COMPANY + paths.EMPLOYEE}
          component={EmployeeUpdate}
          exact
        />

        <Route path={paths.COMPANY + paths.SERVICES} component={Services} />

        <Route
          path={paths.COMPANY + paths.GIFTCARD_DETAILS}
          component={GiftcardDetails}
          exact
        />

        {(shouldRenderManagerRoutes || shouldRenderAccountantRoutes) && (
          <Route
            path={paths.COMPANY + paths.BILLING}
            component={YourCompany}
            exact
          />
        )}

        {shouldRenderManagerRoutes &&
          managerRoutes.map(routeProps => <Route {...routeProps} />)}

        <Route path="*" component={RedirectNotFound} />
      </Switch>
    </Suspense>
  );
};
