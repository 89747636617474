import { ReactNode } from 'react';
import { FaBirthdayCake, FaBlackTie } from 'react-icons/fa';
import { Icon } from 'components/Icon';
import { useTranslation } from 'hooks/translation';

import {
  Description,
  DialogContent,
  OptionButtonStyled,
} from '../../BenefitBookDialog.styled';
import { useBenefitBook } from '../../useBenefitBook';

export enum BenefitType {
  DE_SACHBEZUG = 'de:sachbezug',
  DE_PRAESENT = 'de:praesent',
  DE_EXTRA = 'de:extra',
}

export const SelectBenefit = () => {
  const { changeBenefit, employeeAllowances, goNext } = useBenefitBook();

  const { t } = useTranslation('company');

  const getBenefitIcon = (type: Benefit['benefitTypeId']) => {
    if (type === 'de:praesent') {
      return <Icon icon={FaBirthdayCake} size="1.25rem" />;
    }

    return <Icon icon={FaBlackTie} size="1.25rem" />;
  };

  const getBenefitDescription = (type: Benefit['benefitTypeId']) => {
    const benefitsDescription: Record<Benefit['benefitTypeId'], ReactNode> = {
      'de:praesent': t(
        'benefits.benefitBookDialog.selectBenefit.dePrasentDescription',
      ),
      'de:sachbezug': t(
        'benefits.benefitBookDialog.selectBenefit.deSachbezugDescription',
      ),
      'de:extra': t(
        'benefits.benefitBookDialog.selectBenefit.deExtraDescription',
      ),
    };

    return benefitsDescription[type] ?? '';
  };

  const handleSelectBenefit = (benefit: Benefit) => () => {
    changeBenefit(benefit);
    goNext();
  };

  return (
    <DialogContent data-testid="benefit-book-select-benefit">
      {employeeAllowances.map(benefit => (
        <OptionButtonStyled
          key={benefit.benefitName}
          onClick={handleSelectBenefit(benefit)}
          data-testid="benefit-option"
        >
          <span>{getBenefitIcon(benefit.benefitTypeId)}</span>

          <Description data-testid="benefit-info">
            <strong>{benefit.benefitName}</strong>

            <p>{getBenefitDescription(benefit.benefitTypeId)}</p>
          </Description>
        </OptionButtonStyled>
      ))}
    </DialogContent>
  );
};
