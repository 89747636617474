import { CircularProgress, FormControl, MenuItem, Theme } from '@mui/material';
import styled, { css } from 'styled-components';
import { makeStyles } from '@mui/styles';

interface FormControlStyledProps {
  $hasError?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  listSubHeaderRoot: {
    backgroundColor: theme.palette.zml.grey200,
    color: theme.palette.zml.grey900,
    fontWeight: 600,
  },
}));

export const FormControlStyled = styled(FormControl)<FormControlStyledProps>`
  ${({ margin }) =>
    margin !== 'none' &&
    css`
      margin-block: 1rem 0.5rem;
    `}

  .MuiFormLabel-asterisk {
    color: ${props => props.theme.palette.primary.main};
  }

  ${props =>
    props.$hasError &&
    css`
      .MuiInputBase-root {
        > svg {
          margin-right: 2rem;
        }
      }
    `}
`;

export const CircularProgressStyled = styled(CircularProgress)`
  margin-right: 1.5rem;
`;

export const JoinNetworkButton = styled.button`
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;

  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0.375rem 1rem;
  margin-bottom: 0.5rem;

  :hover {
    text-decoration: underline;
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.palette.zml.grey100};
    `}
`;
