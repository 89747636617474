import urlcat from 'urlcat';
import { saveAs } from 'file-saver';

import { api } from 'services/api';
import { NetworksEndpoints } from 'utils/endpoints';
import {
  networkAccountStatementAdapter,
  networkStatisticsAdapter,
  networkAnalyticsAdapter,
} from 'adapters';

export const getNetworkByDomainName = async (domainName: string) => {
  try {
    const response = await api.get<Network | undefined>(
      NetworksEndpoints.GET_NETWORK_SEARCH,
      {
        params: { query: domainName },
      },
    );

    return response.data;
  } catch (error) {
    /*
       IE 11 SUPPORT
    */
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getNetworkById = async (networkId: string) => {
  const url = urlcat(NetworksEndpoints.GET_NETWORK, {
    networkId,
  });

  const response = await api.get<Network>(url);

  return response.data;
};

export interface getNetworkStoresByQueryParams {
  networkId: string;
  page?: number;
  pageSize?: number;
}

export const getStoreByNetworkId = async (
  params: getNetworkStoresByQueryParams,
) => {
  const { networkId, ...requestParams } = params;

  const url = urlcat(NetworksEndpoints.GET_NETWORK_STORES, {
    networkId,
  });

  const response = await api.get<Paginated<Store>>(url, {
    params: requestParams,
  });

  return response.data;
};

export const getTeamMembersByNetworkId = async (networkId: string) => {
  const url = urlcat(NetworksEndpoints.GET_TEAM_MEMBERS, { networkId });

  const response = await api.get<TeamMember[]>(url);

  return response.data;
};

interface UpdateTeamMemberParams {
  networkId: string;
  userId: string;
  authRole: AuthRole;
}

export const updateTeamMember = async (params: UpdateTeamMemberParams) => {
  const { networkId, userId, ...body } = params;

  const url = urlcat(NetworksEndpoints.UPDATE_TEAM_MEMBER, {
    networkId,
    userId,
  });

  const response = await api.put(url, body);

  return response.data;
};

interface DeleteTeamMemberParams {
  networkId: string;
  userId: string;
}

export const deleteTeamMember = async (params: DeleteTeamMemberParams) => {
  const { networkId, userId } = params;

  const url = urlcat(NetworksEndpoints.DELETE_TEAM_MEMBER, {
    networkId,
    userId,
  });

  const response = await api.delete(url);

  return response.data;
};

export interface CreateTeamMemberParams {
  networkId: string;
  email: string;
  authRole: AuthRole;
}

export const createTeamMember = async (params: CreateTeamMemberParams) => {
  const { networkId, ...body } = params;

  const url = urlcat(NetworksEndpoints.CREATE_TEAM_MEMBER, {
    networkId,
  });

  const response = await api.post(url, body);

  return response.data;
};

export const getNetworkStatistics = async (networkId: string) => {
  const url = urlcat(NetworksEndpoints.GET_NETWORK_STATISTICS, {
    networkId,
  });

  const response = await api.get<NetworkStatistics>(url);

  const adaptedNetworkStatistics = networkStatisticsAdapter(response.data);

  return adaptedNetworkStatistics;
};

export interface GetNetworkStoreByQueryParams {
  networkId: string;
  q: string;
  page?: number;
  pageSize: number;
}

export const getNetworkStoreByQuery = async (
  params: GetNetworkStoreByQueryParams,
) => {
  const { networkId, ...requestParams } = params;

  const url = urlcat(NetworksEndpoints.GET_NETWORK_STORE_QUERY, {
    networkId,
  });

  const response = await api.get<Paginated<Store>>(url, {
    params: requestParams,
  });

  return response.data;
};

interface GetNetworkAssociatesParams {
  networkId: string;
}

export const getNetworkAssociates = async (
  params: GetNetworkAssociatesParams,
) => {
  const { networkId } = params;

  const url = urlcat(NetworksEndpoints.GET_NETWORK_ASSOCIATES, {
    networkId,
  });

  const response = await api.get<NetworkAssociate[]>(url);

  return response.data;
};

interface GetNetworkApiTenantsParams {
  networkId: string;
}

export const getNetworkApiTenants = async (
  params: GetNetworkApiTenantsParams,
) => {
  const { networkId } = params;

  const url = urlcat(NetworksEndpoints.GET_API_TENANTS, {
    networkId,
  });

  const response = await api.get<ApiTenant[]>(url);

  return response.data;
};

interface AddNetworkApiTenantsParams {
  networkId: string;
  name: string;
}

export const addNetworkApiTenants = async (
  params: AddNetworkApiTenantsParams,
) => {
  const { networkId, name } = params;

  const url = urlcat(NetworksEndpoints.ADD_API_TENANTS, {
    networkId,
  });

  const response = await api.post<ApiTenant>(url, { name });

  return response.data;
};

interface DeleteNetworkApiTenantsParams {
  networkId: string;
  apiTenantId: string;
}

export const deleteNetworkApiTenants = async (
  params: DeleteNetworkApiTenantsParams,
) => {
  const { networkId, apiTenantId } = params;

  const url = urlcat(NetworksEndpoints.DELETE_API_TENANTS, {
    networkId,
    apiTenantId,
  });

  const response = await api.delete<{ success: string }>(url);

  return response.data;
};

export const getAllFeesGroups = async (networkId: string) => {
  const url = urlcat(NetworksEndpoints.GET_ALL_FEES_GROUPS, {
    networkId,
  });

  const response = await api.get<FeesGroup[]>(url);

  return response.data;
};

interface CreateFeesGroupParams {
  networkId: string;
  body: {
    name: string;
    onlineFees: {
      rate: number;
      fixed: number;
    };
    offlineFees: {
      rate: number;
      fixed: number;
    };
  };
}

export const createFeesGroup = async (params: CreateFeesGroupParams) => {
  const { networkId, body } = params;

  const url = urlcat(NetworksEndpoints.CREATE_FEES_GROUP, {
    networkId,
  });

  const response = await api.post<FeesGroup>(url, body);

  return response.data;
};

interface UpdateStoreFeesGroupParams {
  networkId: string;
  storeId: string;
  feesGroupId: string;
}

export const updateStoreFeesGroup = async (
  params: UpdateStoreFeesGroupParams,
) => {
  const { networkId, storeId, feesGroupId } = params;

  const url = urlcat(NetworksEndpoints.UPDATE_STORE_FEES_GROUP, {
    networkId,
    storeId,
  });

  const response = await api.put<StoreNetwork>(url, { feesGroupId });

  return response.data;
};

interface EditFeesGroupParams {
  networkId: string;
  feesGroupId: string;
  body: {
    name: string;
    onlineFees: {
      rate: number;
      fixed: number;
    };
    offlineFees: {
      rate: number;
      fixed: number;
    };
  };
}

export const editFeesGroup = async (params: EditFeesGroupParams) => {
  const { networkId, feesGroupId, body } = params;

  const url = urlcat(NetworksEndpoints.EDIT_FEES_GROUP, {
    networkId,
    feesGroupId,
  });

  const response = await api.put<FeesGroup>(url, body);

  return response.data;
};

interface DeleteFeesGroupParams {
  networkId: string;
  feesGroupId: string;
}

export const deleteFeesGroup = async (params: DeleteFeesGroupParams) => {
  const { networkId, feesGroupId } = params;

  const url = urlcat(NetworksEndpoints.DELETE_FEES_GROUP, {
    networkId,
    feesGroupId,
  });

  const response = await api.delete<FeesGroup>(url);

  return response.data;
};

interface RequestStoreProfilePermission {
  storeId: string;
  networkId: string;
}

export const requestStoreProfilePermission = async (
  params: RequestStoreProfilePermission,
) => {
  const { storeId, networkId } = params;

  const url = urlcat(NetworksEndpoints.REQUEST_STORE_PROFILE_PERMISSION, {
    storeId,
    networkId,
  });

  const response = await api.put<StoreNetwork>(url);

  return response.data;
};

export const getStoresPublicDataCsvByNetworkId = async (networkId: string) => {
  const url = urlcat(NetworksEndpoints.GET_STORES_PUBLIC_DATA_CSV, {
    networkId,
  });

  const response = await api.get(url);

  const file = new Blob([response.data], { type: 'text/csv;charset=utf-8' });

  return file;
};

export const getRetailersPrivateDataCsvByNetworkId = async (
  networkId: string,
) => {
  const url = urlcat(NetworksEndpoints.GET_RETAILERS_PRIVATE_DATA_CSV, {
    networkId,
  });

  const response = await api.get(url);

  const file = new Blob([response.data], { type: 'text/csv;charset=utf-8' });

  return file;
};

interface GetAccountStatementsParams {
  networkId: string;
  forYear?: string;
  page?: number;
  pageSize?: number;
}

export const getAccountStatements = async (
  params: GetAccountStatementsParams,
): Promise<Paginated<AdaptedAccountStatement>> => {
  const { networkId, page, pageSize } = params;

  const url = urlcat(NetworksEndpoints.GET_ACCOUNT_STATEMENTS, {
    networkId,
  });

  const response = await api.get<Paginated<AccountStatement>>(url, {
    params: { page, pageSize },
  });

  const adaptedAccountStatements = response.data.items.map(
    networkAccountStatementAdapter,
  );

  return { ...response.data, items: adaptedAccountStatements };
};

interface GetAccountStatementPdfParams {
  forDate: string;
  referenceDate: string;
  filename: string;
  resourceId: string;
}

export const getAccountStatementPdf = async (
  params: GetAccountStatementPdfParams,
) => {
  const { forDate, filename, referenceDate, resourceId } = params;

  const url = urlcat(NetworksEndpoints.GET_ACCOUNT_STATEMENT_PDF, {
    resourceId,
  });

  const response = await api.get(url, {
    params: { forDate, filename, referenceDate },
    responseType: 'blob',
  });

  saveAs(response.data, filename);

  return response.data;
};

interface GetNetworkAnalyticsParams {
  networkId: string;
  fromDate: string;
  toDate: string;
}

export const getNetworkAnalytics = async (
  params: GetNetworkAnalyticsParams,
) => {
  const { networkId, fromDate, toDate } = params;

  const url = urlcat(NetworksEndpoints.NETWORK_ANALYTICS, {
    networkId,
  });

  const response = await api.get(url, { params: { fromDate, toDate } });

  const adaptedNetworkAnalytics = networkAnalyticsAdapter(response.data);

  return adaptedNetworkAnalytics;
};
