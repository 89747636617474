import { api } from 'services/api';
import { AuthEndpoints } from 'utils/endpoints';

export interface CreateAuthenticationParams {
  email: string;
  password: string;
  stayLoggedIn: boolean;
  includeRetailers?: boolean;
  includeCompanies?: boolean;
  includeNetworks?: boolean;
}

export const createAuthentication = async (
  params: CreateAuthenticationParams,
) => {
  const response = await api.post<User | undefined>(AuthEndpoints.AUTH, params);

  return response.data;
};

export interface GetUserDataParams {
  includeRetailers?: boolean;
  includeNetworks?: boolean;
  includeCompanies?: boolean;
  resolveAll?: boolean;
}

export const getUserData = async (params?: GetUserDataParams) => {
  try {
    const response = await api.get<User | undefined>(AuthEndpoints.WHOAMI, {
      params,
    });

    return response.data;
  } catch (error) {
    /*
       IE 11 SUPPORT
    */
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const deleteAuthentication = async () => {
  return api.post(AuthEndpoints.LOGOUT);
};

interface CreateAccountParams {
  name?: string;
  email: string;
  password: string;
  login?: boolean;
}

export const createAccount = async (params: CreateAccountParams) => {
  const response = await api.post<User | undefined>(
    AuthEndpoints.SIGN_UP,
    params,
  );

  return response.data;
};

interface CreateResetPasswordLinkParams {
  email: string;
}

interface CreateResetPasswordLinkResponse {
  success: boolean;
}

export const createResetPasswordLink = async (
  params: CreateResetPasswordLinkParams,
) => {
  const { email } = params;

  const response = await api.post<CreateResetPasswordLinkResponse>(
    AuthEndpoints.FORGOT_PASSWORD,
    { email },
  );

  return response.data;
};

interface ResetPasswordParams {
  userId: string;
  token: string;
  password: string;
}

export const resetPassword = async (params: ResetPasswordParams) => {
  const { userId, token, password } = params;

  const response = await api.post<User>(AuthEndpoints.RESET_PASSWORD, {
    userId,
    token,
    password,
  });

  return response.data;
};
