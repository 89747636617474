import { ChangeEvent, MouseEvent } from 'react';
import { IconButton, TextFieldProps } from '@mui/material';
import { isValid } from 'date-fns';
import {
  DateTimeValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { MdClear } from 'react-icons/md';

import { useTranslation } from 'hooks/translation';
import { TextFieldForm } from 'components/Forms/TextFieldForm';
import { parseFormattedDate } from 'utils/helpers';
import { Icon } from 'components/Icon';

import { IconButtonContainer } from './DateInput.styled';
import { MaskedDateInput } from './MaskedDateInput';

type Props = TextFieldProps & {
  errortext?: string;
  isFilled?: boolean;
  clearValue?(): void;
  inputTestid?: string;
  placeholder?: string;
  onChange?(
    date: Date | null,
    _: PickerChangeHandlerContext<DateTimeValidationError>,
    _inputValue: string,
  ): void;
};

export type DateInputProps = Props;

export const DateInput = (props: Props) => {
  const {
    isFilled,
    clearValue,
    inputTestid,
    onChange,
    onClick,
    onFocus,
    onBlur,
    errortext,
    placeholder,
    label,
    disabled,
    inputRef,
    ...rest
  } = props;

  const { t } = useTranslation('form');

  const hasError = Boolean(errortext);

  const onClearValue = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    clearValue?.();
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedDate = parseFormattedDate(event.target.value);

    if (isValid(parsedDate)) {
      onChange?.(parsedDate, { validationError: null }, event.target.value);

      return;
    }

    onChange?.(null, { validationError: null }, event.target.value);
  };

  return (
    <TextFieldForm
      inputRef={inputRef}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      label={label}
      disabled={disabled}
      error={hasError}
      errortext={errortext}
      helperText={null}
      fullWidth
      onChange={onInputChange}
      placeholder={placeholder}
      inputProps={{
        ...rest?.inputProps,
        'data-testid': inputTestid,
        placeholder: t('dateFormat', {
          disableParentElement: true,
        }).toUpperCase(),
        value: rest?.value,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...rest.InputProps,
        inputComponent: MaskedDateInput,
        endAdornment: (
          <IconButtonContainer>
            {isFilled && (
              <IconButton
                onClick={onClearValue}
                data-testid="clear-date-button"
                size="medium"
                disabled={disabled}
              >
                <Icon icon={MdClear} size="1.2rem" />
              </IconButton>
            )}

            {rest.InputProps?.endAdornment}
          </IconButtonContainer>
        ),
      }}
    />
  );
};
