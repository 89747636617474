import { useMemo, createContext, ReactNode, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { getEmployeeAllowances } from 'repositories/employees';
import { NormalFlowSteps } from './steps';

import { BenefitType } from './components/SelectBenefit';

export type DeliveryFormat = 'card' | 'digital';

export type BookType = 'one-time' | 'automated';

export type BenefitFlow = {
  [key in keyof typeof NormalFlowSteps]?: number;
};

interface BenefitStep {
  name: keyof typeof NormalFlowSteps;
  step: number;
}

export interface Delivery {
  name: string;
  type: DeliveryFormat;
  description: string;
}

interface AutomatedBenefitData {
  interval: AutomatedBenefitInterval;
  startsAt: Date;
  expiresAt: Date;
}

export interface GiftcardData {
  id?: string;
  shortId?: string;
  networkId: string;
  networkName: string;
  amount: number;
  description?: string;
  formattedAmount: string;
  isDynamic?: boolean;
}

interface BenefitTransactionId {
  benefitTransactionId?: string;
  giftcardId?: string;
}

interface useBenefitBookData {
  employee: Employee | null;
  changeEmployee(data: Employee | null): Promise<{ allowances: Benefit[] }>;
  employeeAllowances: Benefit[];
  benefit: Benefit | null;
  changeBenefit(data: Benefit | null): void;
  delivery: Delivery | null;
  changeDelivery(data: Delivery | null): void;
  giftcardData: GiftcardData | null;
  changeGiftcardData(data: GiftcardData): void;
  initialBenefit: BenefitType | null;
  changeInitialBenefit(data: BenefitType | null): void;
  bookType: BookType | null;
  changeBookType(data: BookType | null): void;
  deliveryType: DeliveryType | null;
  changeDeliveryType(data: DeliveryType | null): void;
  automatedBenefitData: AutomatedBenefitData | null;
  changeAutomatedBenefitData(data: AutomatedBenefitData | null): void;
  steps: BenefitStep[];
  changeSteps(data: BenefitFlow): void;
  goBack(): void;
  goNext(gotoStep?: keyof typeof NormalFlowSteps): void;
  currentStep: BenefitStep;
  idempotencyKey: string;
  benefitTransactionId: BenefitTransactionId | null;
  changeBenefitTransactionId(data: BenefitTransactionId | null): void;
}

const BenefitBookContext = createContext({} as useBenefitBookData);

interface Props {
  children: ReactNode;
}

export const BenefitBookProvider = (props: Props) => {
  const { children } = props;

  const [steps, setSteps] = useState<BenefitStep[]>([]);
  const [currentStep, setCurrentStep] = useState({} as BenefitStep);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [employeeAllowances, setEmployeeAllowances] = useState<Benefit[]>([]);
  const [benefit, setBenefit] = useState<Benefit | null>(null);
  const [delivery, setDelivery] = useState<Delivery | null>(null);
  const [deliveryType, setDeliveryType] = useState<DeliveryType | null>(null);
  const [giftcardData, setGiftcardData] = useState<GiftcardData | null>(null);
  const [bookType, setBookType] = useState<BookType | null>(null);
  const [automatedBenefitData, setAutomatedBenefitData] =
    useState<AutomatedBenefitData | null>(null);
  const [initialBenefit, setInitialBenefit] = useState<BenefitType | null>(
    null,
  );
  const [benefitTransactionId, setBenefitTransactionId] =
    useState<BenefitTransactionId | null>(null);

  const idempotencyKey = useMemo(
    () => uuid(),
    [
      employee?.id,
      benefit?.benefitTypeId,
      delivery?.type,
      giftcardData?.id,
      giftcardData?.amount,
    ],
  );

  const goNext = (gotoStep?: keyof typeof NormalFlowSteps) => {
    if (gotoStep) {
      const nextStep = steps.find(step => step.name === gotoStep);

      if (!nextStep) return;

      setCurrentStep(nextStep);
      return;
    }

    const currentStepIndex = steps.findIndex(
      step => step.step === currentStep.step,
    );

    if (!(currentStepIndex >= 0)) return;

    const nextStep = steps[currentStepIndex + 1];

    setCurrentStep(nextStep);
  };

  const goBack = () => {
    if (currentStep.name === 'SELECT_DELIVERY') {
      if (bookType === 'automated') {
        const automatedBenefitStep = steps.find(
          step => step.name === 'AUTOMATED_BENEFIT',
        );

        if (!automatedBenefitStep) return;

        setCurrentStep(automatedBenefitStep);
        return;
      }

      const selectBookTypeStep = steps.find(
        step => step.name === 'SELECT_BOOK_TYPE',
      );

      if (!selectBookTypeStep) return;

      setCurrentStep(selectBookTypeStep);
      return;
    }

    const currentStepIndex = steps.findIndex(
      step => step.step === currentStep.step,
    );

    if (!(currentStepIndex >= 0)) return;

    const prevStep = steps[currentStepIndex - 1];

    setCurrentStep(prevStep);
  };

  const changeDelivery = (data: Delivery | null) => {
    setDelivery(data);
  };

  const changeEmployee = async (data: Employee | null) => {
    if (!data) {
      setEmployee(null);
      setEmployeeAllowances([]);
      return { allowances: [] };
    }

    const allowances = await getEmployeeAllowances(data.id);

    if (initialBenefit) {
      const _benefit = allowances.find(
        findBenefit => findBenefit.benefitTypeId === initialBenefit,
      );

      if (_benefit) setBenefit(_benefit);
    }

    setEmployee(data);
    setEmployeeAllowances(allowances);

    return { allowances };
  };

  const changeBenefit = (data: Benefit | null) => {
    setBenefit(data);
  };

  const changeGiftcardData = (data: GiftcardData) => {
    setGiftcardData(data);
  };

  const changeAutomatedBenefitData = (data: AutomatedBenefitData | null) => {
    setAutomatedBenefitData(data);
  };

  const changeInitialBenefit = (data: BenefitType | null) => {
    setInitialBenefit(data);
  };

  const changeBookType = (data: BookType | null) => {
    setBookType(data);
  };

  const changeDeliveryType = (data: DeliveryType | null) => {
    setDeliveryType(data);
  };

  const changeSteps = (data: BenefitFlow) => {
    const _steps = Object.entries(data).map(([key, value]) => ({
      name: key as keyof typeof NormalFlowSteps,
      step: value,
    }));

    setCurrentStep(_steps[0]);
    setSteps(_steps);
  };

  const changeBenefitTransactionId = (data: BenefitTransactionId | null) => {
    setBenefitTransactionId(data);
  };

  // TODO: refactor and use useMemo instead
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value: useBenefitBookData = {
    employee,
    employeeAllowances,
    changeEmployee,
    benefit,
    changeBenefit,
    delivery,
    changeDelivery,
    giftcardData,
    changeGiftcardData,
    initialBenefit,
    changeInitialBenefit,
    bookType,
    changeBookType,
    deliveryType,
    changeDeliveryType,
    automatedBenefitData,
    changeAutomatedBenefitData,
    steps,
    changeSteps,
    goBack,
    goNext,
    currentStep,
    idempotencyKey,
    benefitTransactionId,
    changeBenefitTransactionId,
  };

  return (
    <BenefitBookContext.Provider value={value}>
      {children}
    </BenefitBookContext.Provider>
  );
};

export const useBenefitBook = () => useContext(BenefitBookContext);
