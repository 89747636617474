import styled from 'styled-components';
import { DialogContent } from '../../BenefitBookDialog.styled';

export const DialogContentStyled = styled(DialogContent)`
  min-height: 22.278rem;

  > form {
    margin-bottom: 0;

    > div:nth-child(3) > button {
      margin-top: 4.5rem;
    }
  }
`;

interface GiftcardContainerProps {
  $hasGiftcard?: boolean;
}

export const GiftcardContainer = styled.div<GiftcardContainerProps>`
  display: flex;
  align-items: center;
  height: 7.063rem;
  width: 100%;

  visibility: ${({ $hasGiftcard }) => ($hasGiftcard ? 'visible' : 'hidden')};

  border: none;
  border-radius: 0.25rem;

  padding-block: 0.5rem;
  padding-inline: 0.5rem;

  margin-block: 1rem 0.5rem;

  background-color: ${({ theme }) => theme.palette.zml.grey50};
  color: ${({ theme }) => theme.palette.zml.blue800};
  box-shadow: 0 0.188rem 0.375rem
    ${({ theme }) => theme.palette.zml.black14Opacity};

  & + & {
    margin-top: 2rem;
  }
`;

export const NetworkImg = styled.img`
  height: 5.75rem;
  width: 5.75rem;
  border-radius: 0.375rem;
`;

export const GiftcardInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding-block: 0.375rem;
  margin-left: 1rem;

  strong {
    font-size: 1.063rem;
    font-weight: 400;
    text-align: left;
  }

  span {
    font-size: 1.563rem;
    text-align: left;
    margin: 1rem 0 0 0;
  }
`;
