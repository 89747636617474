import { useLocation } from 'react-router';
import { List } from '@mui/material';
import { IconType } from 'react-icons/lib';
import {
  FaCloudDownloadAlt,
  FaCoins,
  FaReceipt,
  FaTachometerAlt,
} from 'react-icons/fa';
import { AiOutlineBarChart } from 'react-icons/ai';
import { MdTimeline } from 'react-icons/md';
import paths, {
  getPathname,
  isCompany,
  isNetwork,
  isRetailer,
} from 'utils/paths';
import { getI18nNamespace } from 'utils/helpers';
import { ReactComponent as StoreIcon } from 'assets/icon/store.svg';
import { ReactComponent as TieIcon } from 'assets/icon/benefit.svg';
import { MenuItem } from './MenuItem';

export interface SidebarMenuItem {
  description: string;
  icon: IconType | SVG;
  path: string;
  subPages?: string[];
}

const retailerTranslator = getI18nNamespace('retailer');

export const retailerMainMenuItems: SidebarMenuItem[] = [
  {
    description: retailerTranslator('retailers.sidebar.dashboard'),
    icon: FaTachometerAlt,
    path: paths.DASHBOARD,
  },
  {
    description: retailerTranslator('retailers.sidebar.transactions'),
    icon: AiOutlineBarChart,
    path: paths.TRANSACTIONS,
    subPages: [paths.GIFTCARDS_DETAILS],
  },
  // {
  //   description: retailerTranslator('retailers.sidebar.marketing'),
  //   icon: FaRocket,
  //   path: paths.MARKETING,
  // },
];

const companyTranslator = getI18nNamespace('company');

export const companyMainMenuItems: SidebarMenuItem[] = [
  {
    description: companyTranslator('benefits.sidebar.dashboard'),
    icon: FaTachometerAlt,
    path: paths.DASHBOARD,
  },
  {
    description: companyTranslator('benefits.sidebar.transactions'),
    icon: AiOutlineBarChart,
    path: paths.TRANSACTIONS,
    subPages: [paths.AUTOMATED_BENEFIT_DETAILS, paths.GIFTCARD_DETAILS],
  },
  {
    description: companyTranslator('benefits.sidebar.employees'),
    icon: TieIcon,
    path: paths.EMPLOYEES,
    subPages: [paths.EMPLOYEE],
  },
  {
    description: companyTranslator('benefits.sidebar.services'),
    icon: FaCloudDownloadAlt,
    path: paths.SERVICES,
  },
];

const getCompanyMenu = () => {
  const menu = [...companyMainMenuItems];

  return menu;
};

const networkTranslator = getI18nNamespace('network');

export const networkMainMenuItems: SidebarMenuItem[] = [
  {
    description: networkTranslator('networks.sidebar.dashboard'),
    icon: FaTachometerAlt,
    path: paths.DASHBOARD,
  },
  {
    description: networkTranslator('networks.sidebar.analytics'),
    icon: MdTimeline,
    path: paths.ANALYTICS,
  },
  {
    description: networkTranslator('networks.sidebar.stores'),
    icon: StoreIcon,
    path: paths.STORES,
  },
  {
    description: networkTranslator('networks.sidebar.benefits'),
    icon: TieIcon,
    path: paths.BENEFITS,
  },
  {
    description: networkTranslator('networks.sidebar.fees'),
    icon: FaCoins,
    path: paths.FEES,
  },
  {
    description: networkTranslator('networks.sidebar.statements'),
    icon: FaReceipt,
    path: paths.STATEMENTS,
  },
  /*
  {
    description: networkTranslator('networks.sidebar.benefitsAccounts'),
    icon: FaBlackTie,
    path: paths.EMPLOYER,
  },
  {
    description: networkTranslator('networks.sidebar.revenue'),
    icon: GoGraph,
    path: paths.SALES_OVERVIEW,
  },
  {
    description: networkTranslator('networks.sidebar.reports'),
    icon: HiDocumentReport,
    path: paths.REPORTS,
  },
  */
];

export const getMainMenuItems = (currentPath?: string) => {
  const pathname = getPathname();

  const path = currentPath || pathname;

  if (isCompany(path)) {
    return getCompanyMenu();
  }

  if (isRetailer(path)) {
    return retailerMainMenuItems;
  }

  if (isNetwork(path)) {
    return networkMainMenuItems;
  }

  return [];
};

interface Props {
  toggleDrawer?: () => void;
}

export const MainMenu = (props: Props) => {
  const { toggleDrawer } = props;

  const { pathname } = useLocation();

  const resolveNetworkMenuItems = () => {
    return getMainMenuItems(pathname);
  };

  const resolveCompanyMenuItems = () => {
    return getMainMenuItems(pathname);
  };

  const resolveMenuItems = () => {
    if (isNetwork(pathname)) return resolveNetworkMenuItems();

    if (isCompany(pathname)) return resolveCompanyMenuItems();

    return getMainMenuItems(pathname);
  };

  const mainMenuItems = resolveMenuItems();

  return (
    <List>
      {mainMenuItems?.map(menu => (
        <MenuItem
          key={menu.description}
          description={menu.description}
          path={menu.path}
          icon={menu.icon}
          onClick={toggleDrawer}
        />
      ))}
    </List>
  );
};
