import urlcat from 'urlcat';
import { AxiosProgressEvent } from 'axios';
import { api } from 'services/api';
import { CompaniesEndpoints, NetworksEndpoints } from 'utils/endpoints';
import { BookingReasons } from 'utils/api';
import { invoiceAdapter } from 'adapters';
import { automatedBenefitAdapter } from 'adapters/zmyle/automatedBenefit/automatedBenefit';

export const getCompanyById = async (id: string) => {
  const url = urlcat(CompaniesEndpoints.GET_COMPANY, {
    companyId: id,
  });

  const response = await api.get<Company | undefined>(url);

  return response.data;
};

export interface UpdateCompanyByIdParams {
  id: string;
  name: string;
  name2: string;
  email: string;
  vatId: string;
  addressStreet: string;
  addressZipcode: string;
  addressCity: string;
  bankAccount: string | null;
  approvedSepaDirectDebitAt?: string;
}

type UpdateCompanyByIdResponse = Company | undefined;

export const updateCompanyById = async (params: UpdateCompanyByIdParams) => {
  const { id, ...contact } = params;

  const url = urlcat(CompaniesEndpoints.UPDATE_COMPANY, {
    companyId: id,
  });

  const body = { contact };

  const response = await api.put<UpdateCompanyByIdResponse>(url, body);

  return response.data;
};

export interface CreateCompanyParams {
  userId?: string;
  name: string;
  name2?: string;
  addressStreet: string;
  addressZipcode: string;
  addressCity: string;
  email: string;
}

export const createCompany = async (params: CreateCompanyParams) => {
  const { userId, ...contact } = params;
  const body = { userId, contact };

  const response = await api.post<Company | undefined>(
    CompaniesEndpoints.COMPANIES,
    body,
  );

  return response?.data;
};

export interface CompanyGiftcardSearchParams {
  companyId: string;
  shortId: string;
}

export const searchCompanyGiftcard = async (
  params: CompanyGiftcardSearchParams,
) => {
  const { companyId, shortId } = params;

  const url = urlcat(CompaniesEndpoints.GET_GIFTCARD_SEARCH, {
    companyId,
    shortId,
  });

  const response = await api.get<MiniGiftcard[]>(url);

  return response.data;
};

export interface GetEmployeesByCompanyIdParams {
  companyId: string;
  page?: number;
}

export const getEmployeesByCompanyId = async (
  params: GetEmployeesByCompanyIdParams,
) => {
  const { companyId, ...requestParams } = params;

  const url = urlcat(CompaniesEndpoints.GET_EMPLOYEES, {
    companyId,
  });

  const response = await api.get<Paginated<Employee> | undefined>(url, {
    params: requestParams,
  });

  return response.data;
};

export interface GetEmployeesByQueryParams {
  companyId: string;
  page?: number;
  query: string;
}

export const getEmployeesByQuery = async (
  params: GetEmployeesByQueryParams,
) => {
  const { companyId, ...requestParams } = params;

  const url = urlcat(CompaniesEndpoints.GET_EMPLOYEES_SEARCH, {
    companyId,
  });

  const response = await api.get<Employee[]>(url, { params: requestParams });

  return response.data;
};

export interface CreateOneTimeBenefitParams {
  networkId: string;
  employeeId: string;
  benefitTypeId: string;
  giftcardId?: string;
  amount: number;
  description?: string;
  deliveryType: DeliveryType;
  idempotencyKey: string;
}

export interface CreateOneTimeBenefitResponse {
  reason: BookingReasons;
  success: boolean;
  benefitTransaction?: {
    id: string;
    giftcardId: string;
  };
}

export const createOneTimeBenefit = async (
  data: CreateOneTimeBenefitParams,
) => {
  const response = await api.post<CreateOneTimeBenefitResponse>(
    CompaniesEndpoints.BENEFIT_TRANSACTIONS,
    data,
  );

  return response.data;
};

export interface CreateAutomatedBenefitParams {
  employeeId: string;
  networkId: string;
  frequency: AutomatedBenefitInterval;
  amount: number;
  description?: string;
  benefitTypeId: string;
  startsAt: string;
  expiresAt: string;
  deliveryType?: DeliveryType;
  giftcardId?: string;
  simulate?: boolean;
  idempotencyKey?: string;
}

export interface CreateAutomatedBenefitResponse {
  reason: BookingReasons;
  success: boolean;
  automatedBenefit?: AutomatedBenefit;
}

export const createAutomatedBenefit = async (
  params: CreateAutomatedBenefitParams,
) => {
  const { simulate = false, ...body } = params;

  const response = await api.post<CreateAutomatedBenefitResponse>(
    CompaniesEndpoints.AUTOMATED_BENEFITS,
    body,
    {
      params: {
        simulate,
      },
    },
  );

  return response.data;
};

interface GetAutomatedBenefitsByCompanyIdParams {
  companyId: string;
  page?: number;
  pageSize?: number;
}

export const getAutomatedBenefitsByCompanyId = async (
  params: GetAutomatedBenefitsByCompanyIdParams,
): Promise<Paginated<AdaptedAutomatedBenefit>> => {
  const { companyId, page, pageSize } = params;

  const url = urlcat(CompaniesEndpoints.GET_AUTOMATED_BENEFITS, {
    companyId,
  });

  const response = await api.get<Paginated<AutomatedBenefit>>(url, {
    params: { page, pageSize },
  });

  const adaptedAutomatedBenefits = response.data.items.map(
    automatedBenefitAdapter,
  );

  return { ...response.data, items: adaptedAutomatedBenefits };
};

interface JoinCompanyToNetworkParams {
  companyId: string;
  networkId: string;
}

export const joinCompanyToNetwork = async (
  params: JoinCompanyToNetworkParams,
) => {
  const { companyId, networkId } = params;
  const url = urlcat(CompaniesEndpoints.JOIN_NETWORK, {
    companyId,
  });

  const response = await api.post(url, { networkId });

  return response.data;
};

export interface CreateTeamMemberParams {
  companyId: string;
  email: string;
  authRole: AuthRole;
}

export const createTeamMember = async (params: CreateTeamMemberParams) => {
  const { companyId, ...body } = params;

  const url = urlcat(CompaniesEndpoints.CREATE_TEAM_MEMBER, {
    companyId,
  });

  const response = await api.post(url, body);

  return response.data;
};

interface UpdateTeamMemberParams {
  companyId: string;
  userId: string;
  authRole: AuthRole;
}

export const updateTeamMember = async (params: UpdateTeamMemberParams) => {
  const { companyId, userId, ...body } = params;

  const url = urlcat(CompaniesEndpoints.UPDATE_TEAM_MEMBER, {
    companyId,
    userId,
  });

  const response = await api.put(url, body);

  return response.data;
};

interface DeleteTeamMemberParams {
  companyId: string;
  userId: string;
}

export const deleteTeamMember = async (params: DeleteTeamMemberParams) => {
  const { companyId, userId } = params;

  const url = urlcat(CompaniesEndpoints.DELETE_TEAM_MEMBER, {
    companyId,
    userId,
  });

  const response = await api.delete(url);

  return response.data;
};

export const getTeamMembersByCompanyId = async (companyId: string) => {
  const url = urlcat(CompaniesEndpoints.GET_TEAM_MEMBERS, { companyId });

  const response = await api.get<TeamMember[]>(url);

  return response.data;
};

interface GetBenefitsParams {
  networkId: string;
  page: number;
  pageSize: number;
}

export const getCompaniesByNetwork = async (params: GetBenefitsParams) => {
  const { networkId, page, pageSize } = params;

  const url = urlcat(NetworksEndpoints.GET_COMPANIES_BY_NETWORK, {
    networkId,
  });

  const response = await api.get<Paginated<Company>>(url, {
    params: { page, pageSize },
  });

  return response.data;
};

interface GetInvoicesByCompanyIdParams {
  companyId: string;
  pageSize?: number;
  page?: number;
}

export const getInvoicesByCompanyId = async (
  params: GetInvoicesByCompanyIdParams,
) => {
  const { companyId, pageSize, page } = params;

  const url = urlcat(CompaniesEndpoints.GET_INVOICES, { companyId });

  const response = await api.get<Paginated<Invoice>>(url, {
    params: { pageSize, page },
  });

  const adaptedInvoices = response.data.items.map(invoiceAdapter);

  return {
    ...response.data,
    items: adaptedInvoices,
  };
};

interface UpdateGiftcardFrontPagePictureParams {
  companyId: string;
  giftcardFrontpagePicture: string;
  onUploadProgress?(event: AxiosProgressEvent): void;
}

export const updateGiftcardFrontPagePicture = async (
  params: UpdateGiftcardFrontPagePictureParams,
) => {
  const { companyId, giftcardFrontpagePicture, onUploadProgress } = params;

  const url = urlcat(CompaniesEndpoints.UPDATE_GIFTCARD_FRONT_PAGE, {
    companyId,
  });

  const response = await api.post<Company>(
    url,
    { giftcardFrontpagePicture },
    {
      onUploadProgress,
    },
  );

  return response.data;
};

export const getDataProcessingAgreementByCompanyId = async (
  companyId: string,
) => {
  const url = urlcat(CompaniesEndpoints.GET_DATA_PROCESSING_AGREEMENT, {
    companyId,
  });

  const response = await api.get<{ id: string }>(url);

  return response.data;
};

interface GetGiftcardDetailsParams {
  companyId: string;
  giftcardId: string;
}

interface GetGiftcardDetailsResponse extends MiniGiftcard {
  benefitTransactions?: OneTimeBenefit[];
  relevance: {
    contextName: string;
  };
}

export const getGiftcardDetails = async (params: GetGiftcardDetailsParams) => {
  const { companyId, giftcardId } = params;

  const url = urlcat(CompaniesEndpoints.GET_GIFTCARD_DETAILS, {
    companyId,
    giftcardId,
  });

  const response = await api.get<GetGiftcardDetailsResponse>(url);

  return response.data;
};

interface CreateCompanyAppleWalletPicture {
  companyId: string;
  picture?: string;
}

export const createCompanyAppleWalletPicture = async (
  params: CreateCompanyAppleWalletPicture,
) => {
  const { companyId, picture } = params;

  const url = urlcat(CompaniesEndpoints.CREATE_COMPANY_APPLE_WALLET_PICTURE, {
    companyId,
  });

  const response = await api.post(
    url,
    { picture },
    {
      timeout: 1000 * 180, // 3min
    },
  );

  return response.data;
};

interface CreateCompanyGoogleWalletPicture {
  companyId: string;
  picture?: string;
}

export const createCompanyGoogleWalletPicture = async (
  params: CreateCompanyGoogleWalletPicture,
) => {
  const { companyId, picture } = params;

  const url = urlcat(CompaniesEndpoints.CREATE_COMPANY_GOOGLE_WALLET_PICTURE, {
    companyId,
  });

  const response = await api.post(
    url,
    { picture },
    {
      timeout: 1000 * 180, // 3min
    },
  );

  return response.data;
};

interface CreateCompanyGiftcardPdfPicture {
  companyId: string;
  picture?: string;
}

export const createCompanyGiftcardPdfPicture = async (
  params: CreateCompanyGiftcardPdfPicture,
) => {
  const { companyId, picture } = params;

  const url = urlcat(CompaniesEndpoints.CREATE_COMPANY_GIFTCARD_PDF_PICTURE, {
    companyId,
  });

  const response = await api.post(
    url,
    { picture },
    {
      timeout: 1000 * 180, // 3min
    },
  );

  return response.data;
};
