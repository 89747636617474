import { Autocomplete, Stack, Typography } from '@mui/material';
import { HTMLAttributes } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import {
  TextFieldForm,
  TextFieldFormProps,
} from 'components/Forms/TextFieldForm';
import { Icon } from 'components/Icon';
import { useDisclosure } from 'hooks/disclosure';

type DropdownTextFieldValue<T extends DropdownValue> =
  | DropdownOption<T>
  | null
  | string;

type Props<T extends DropdownValue> = Partial<TextFieldFormProps> & {
  options: DropdownOption<T>[];
  onSelectOption(option: DropdownTextFieldValue<T>): void;
};

export const DropdownTextField = <T extends DropdownValue>(props: Props<T>) => {
  const { options, onSelectOption, ...rest } = props;
  const { id, ...textFieldProps } = rest;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderOption = (
    optionProps: HTMLAttributes<HTMLLIElement>,
    option: DropdownOption<T>,
  ) => {
    return (
      <li {...optionProps} key={option.value}>
        <Typography variant="body2">{option.label}</Typography>
      </li>
    );
  };

  const getOptionLabel = (option: DropdownOption<T> | string) => {
    if (typeof option === 'string') return option;

    if (!option || !option.value) return '';

    return option.label;
  };

  const handleSelectOption = (
    _: unknown,
    option: DropdownTextFieldValue<T>,
  ) => {
    if (typeof option === 'string') return;

    onSelectOption(option);
  };

  return (
    <Autocomplete
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      freeSolo
      filterSelectedOptions
      id={id}
      options={options}
      onChange={handleSelectOption}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      clearOnBlur={false}
      openOnFocus
      fullWidth
      renderInput={params => (
        <TextFieldForm
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params?.inputProps,
            ...textFieldProps?.inputProps,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            endAdornment: (
              <Stack>
                {params.InputProps?.endAdornment}
                {textFieldProps.InputProps?.endAdornment}

                {isOpen && <Icon icon={IoMdArrowDropup} size="1.25rem" />}

                {!isOpen && <Icon icon={IoMdArrowDropdown} size="1.25rem" />}
              </Stack>
            ),
          }}
        />
      )}
    />
  );
};
